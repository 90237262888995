import { imgInputFilePicker } from "utils/utils";
import React, { useState, useEffect } from "react";
import myProfileIcon from "assets/images/my-profile.svg";
import editIcon from "assets/images/editProfile.svg";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";
import uploadPhoto from "assets/images/upload-photo.svg";

export default function UploadProfilePhoto({
  handleUpload,
  value,
  prevUrl = false,
  imParentCls = false,
  showIcon = true,
  addPhoto = true,
  gallery = false,
  certificate = false,
}) {
  const [formData, setFormData] = useState();

  const [showPrevUrl, setShowPrevUrl] = useState(prevUrl);

  useEffect(() => {
    if (value) {
      setFormData(value);
    }
  }, [value]);

  const handleUploadFile = async (event) => {
    if (value) {
      return false;
    } else {
      let imageFile = event.target.files[0];

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        // fileType: "image/png",
      };

      if (gallery) {
        let files = [];
        let previewUrls = [];
        let imgs = event.target.files;
        for (const im of Array.from(imgs)) {
          console.log("im :>> ", im);
          let compressedFile = await imageCompression(im, options);
          var previewUrl = URL.createObjectURL(compressedFile);
          var file = new File([compressedFile], im.name, {
            type: im.type,
            size: compressedFile.size,
          });

          files.push(file);
          previewUrls.push(previewUrl);
        }

        // console.log("files :>> ", files);
        // console.log("previewUrls :>> ", previewUrls);
        handleUpload(files, previewUrls);
      } else {
        const compressedFile = await imageCompression(imageFile, options);
        console.log("compressedFile :>> ", compressedFile);

        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        ); // smaller than maxSizeMB
        let previewUrl = URL.createObjectURL(compressedFile);
        if (!certificate) {
          setFormData(previewUrl);
        }
        console.log("previewUrl :>> ", previewUrl);
        console.log("compressedFile :>> ", compressedFile);
        if (handleUpload) {
          if (!certificate) {
            setShowPrevUrl(false);
          }
          var file = new File([compressedFile], imageFile.name, {
            type: imageFile.type,
            size: compressedFile.size,
          });
          handleUpload(file, previewUrl);
        }
      }
    }
  };

  return gallery ? (
    <>
      <input
        type="file"
        onChange={handleUploadFile}
        // accept="image/x-png,image/gif,image/jpeg"
        accept="image/*, .heic"
        multiple
      />
      <div className="media boxmediablue text-white" onClick={handleUpload}>
        <img
          className="align-self-center me-3"
          src={uploadPhoto}
          alt=""
          title=""
        />
        <div className="media-body">
          <h5 className="mt-0">
            <b>Add New Photos</b>
          </h5>
        </div>
      </div>
    </>
  ) : (
    <div className="profilebox customprofilebox">
      <div className={imParentCls ? imParentCls : "userprofile "}>
        {!showIcon ? (
          <input
            type="file"
            // id="choosefile1"
            onChange={handleUploadFile}
            className={"file-input-hidden"}
            // accept="image/x-png,image/gif,image/jpeg"
            accept="image/*, .heic"
          />
        ) : (
          ""
        )}
        <img
          src={showPrevUrl ? prevUrl : formData ? formData : myProfileIcon}
          className={addPhoto ? "profileimg" : ""}
          alt=""
          title=""
        />
        {/* <label htmlFor="choosefile1"></label> */}

        {showIcon ? (
          <span
            className="editprofile customfilebx"
            // onClick={onClickPhotoUpdate}
          >
            <input
              type="file"
              onChange={handleUploadFile}
              className={"file-input-hidden"}
              // accept="image/x-png,image/gif,image/jpeg"
              accept="image/*, .heic"
            />
            <img src={editIcon} alt="" title="" />

            {/* <label htmlFor="choosefile"></label> */}
          </span>
        ) : (
          <p className="mb-0">Upload {certificate ? "Certificate" : "Photo"}</p>
        )}
      </div>
    </div>
  );
}
