import { fileOpen } from "browser-fs-access";
import imageCompression from "browser-image-compression";

export async function csvInputFilePicker() {
  const blob = await fileOpen({
    description: "Csv file",
    mimeTypes: ["text/csv", "application/csv"],
    extensions: [".csv"],
  });

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onload = () => {
      const base64 = reader.result.split(",")[1];
      const dataURL = reader.result.split(",")[0];

      if (dataURL.includes("csv")) {
        resolve(base64);
      } else {
        reject();
      }
    };

    reader.onerror = () => {
      reject();
    };
  });
}

export async function imgsInputFilePicker() {
  const blobs = await fileOpen({
    multiple: true,
    description: "Image files",
    mimeTypes: ["image/jpg", "image/png"],
    extensions: [".jpg", ".jpeg", ".png"],
  });
  let index = 0;
  for (let image of blobs) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 450,
      useWebWorker: true,
    };
    try {
      image = await imageCompression(image, options);
      console.log("image instanceof image", image instanceof Blob); // true
      console.log(`image size ${image.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    } catch (error) {
      console.log(error, "errorrrrrrrrr");
    }
    blobs[index] = image;
    index++;
  }
  if (blobs.length == 0) {
    return new Promise((resolve, reject) => {
      reject("Photo size should be less than 5 MB");
    });
  }
  const promises = blobs.map((blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const img = document.createElement("img");

      reader.readAsDataURL(blob);

      reader.onload = () => {
        img.src = reader.result;
      };

      img.onload = () => {
        resolve({ photo: reader.result.split(",")[1], photoName: blob.name });
      };

      img.onerror = () => {
        reject();
      };
    });
  });

  const results = await Promise.allSettled(promises);

  return results
    .filter((res) => res.status === "fulfilled")
    .map((res) => res.value);

  // return results.reduce((newResult, result) => {
  //   if (result.status === "fulfilled") {
  //     return [...newResult, result.value];
  //   } else {
  //     return newResult;
  //   }
  // }, []);
}

export async function imgInputFilePicker() {
  let blob = await fileOpen({
    description: "Image files",
    mimeTypes: ["image/jpg", "image/png"],
    extensions: [".jpg", ".jpeg", ".png"],
  });

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 450,
    useWebWorker: true,
  };
  try {
    blob = await imageCompression(blob, options);
    console.log("blob instanceof Blob", blob instanceof Blob); // true
    console.log(`blob size ${blob.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    return new Promise((resolve, reject) => {
      if (blob.size > 4194304) {
        console.log(blob.size);
        blob = {};
        reject("Photo size should be less than 5 MB");
      }
      const reader = new FileReader();
      const img = document.createElement("img");

      reader.readAsDataURL(blob);

      reader.onload = () => {
        img.src = reader.result;
      };

      img.onload = () => {
        resolve({ base64: reader.result.split(",")[1], name: blob.name });
      };

      img.onerror = () => {
        reject();
      };
    });
  } catch (error) {
    console.log(error, "errorrrrrrrrr");
  }

  // const input = document.createElement("input");
  // const img = document.createElement("img");

  // input.type = "file";
  // input.setAttribute("accept", ".jpg, .jpeg, .png");
  // const reader = new FileReader();
  // let filename = "";

  // input.onchange = (e) => {
  //     filename = e.target.files[0].name;
  //     reader.readAsDataURL(e.target.files[0]);
  // };

  // reader.onload = () => {
  //     img.src = `data:image/jpeg;base64,${reader.result.split(",")[1]}`
  //     // setFormData({ ...formData, photo: reader.result.split(",")[1], photoName: filename });
  // };

  // img.onload = () => {
  //     setFormData({ ...formData, photo: reader.result.split(",")[1], photoName: filename });
  // }

  // img.onerror = () => {
  //     console.log("error")
  // }

  // input.click();
}

export async function certificateInputFilePicker() {
  const blob = await fileOpen({
    description: "Image and Pdf files",
    mimeTypes: ["application/pdf"],
    extensions: [".pdf"],
  });

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(blob);

    reader.onload = () => {
      resolve({ base64: reader.result.split(",")[1], name: blob.name });
    };

    reader.onerror = () => {
      reject();
    };
  });
}

export function covertBase64ToDataUrl(base64) {
  return `data:image/jpeg;base64,${base64}`;
}
