import React from "react";
import { NavLink } from "react-router-dom";
import back_white from "../assets/images/back_white.svg";
import mailSupport from "../assets/images/mailSupport.svg";
import LocationSupport from "../assets/images/LocationSupport.svg";
import phoneSupport from "../assets/images/phoneSupport.svg";
import supportVideo from "../assets/images/supportVideo.svg";

export default function FAHelpSupport() {
  return (
    <body className="pt80">
      <div className="mainPage">
        <div className="Homeheaderbg headerfixed text-white">
          <div className="d-flex align-self-center">
            <NavLink to="/ManageSetting">
              <img src={back_white} alt="" title="" />
            </NavLink>
            <h2 className="fs20 mb-0 text-center mx-auto">Help/Support</h2>
          </div>
        </div>
        <div className="parent">
          <div className="contentboxgray11">
            <br />
          </div>
        </div>

        <div className="contentBody contentbodymedia">
          <div className="contentBody">
            <h3 className="fsregular">Connect With Us</h3>
            <div className="row pt-3 support">
              <div className="col-lg-12">
                <div className="mb-4">
                  <h4>
                    {" "}
                    <span>
                      <img src={mailSupport} className="me-2" alt="" title="" />
                    </span>
                    hello@bigpurplephone.com.au
                  </h4>
                </div>
                <div className="mb-4">
                  <h4>
                    {" "}
                    <span>
                      <img
                        src={LocationSupport}
                        className="me-2"
                        alt=""
                        title=""
                      />
                    </span>
                    PO Box 837 Moss Vale NSW 2577
                  </h4>
                </div>
                <div className="mb-4">
                  <h4>
                    {" "}
                    <span>
                      <img
                        src={phoneSupport}
                        className="me-2"
                        alt=""
                        title=""
                      />
                    </span>
                    <a href="tel:02-8660-1390">(02) 8660 1390</a>
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                {/* <button type="button" 
                        onClick="" 
                     className="btn btn-primary w-100 mt-3">Submit</button> */}
              </div>
            </div>

            <div className="row">
              {/* <a href="#" className="mx-auto text-center"><img src={supportVideo} className="videocallicon" alt="" title=""/> </a> */}
            </div>
          </div>
        </div>
        {/* <!-- <div className="bottomLogo">
          <a href="#"><img src="assets/images/bigPurplePhoneLogo.svg"></a>
       </div>--> */}
      </div>
      {/* <!-- Js -->
    <script src="assets/js/bootstrap.min.js"></script> */}
    </body>
  );
}
