import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

// import blackTop from "../assets/images/blackTop.svg";
// import customerBorder from "../assets/images/customerBorder.svg";
// import googlePlusLogin from "../assets/images/googlePlusLogin.svg";
// import facebookLogin from "../assets/images/facebookLogin.svg";
import infoFeather from "../assets/images/infoFeather.svg";
import bigPurplePhoneLogo from "../assets/images/bigPurpleLogo-dark.svg";
import { getDataForVerification } from "../api/api";
import { Spinner } from "react-bootstrap";

// import CustomTextField from "../components/CustomTextField";
import CustomPasswordInput from "../components/CustomPasswordInput";

import { register } from "../api/api";
import CustomModel from "../components/CustomModel";
import { tnc } from "../tnc";
import { useSelector } from "react-redux";
import { passwordRegex } from "utils/regex";

import { useFormik } from "formik";
import { PhoneComponent, textField } from "components/InputFields";
import * as Yup from "yup";
import messages from "utils/messages";
import { inputValidation } from "utils/validations";
import { useErrorHandler } from "react-error-boundary";

export default function Registration(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [checked, setCheck] = useState(true);
  const [openTcModal, setOpenTCModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const user = useSelector((state) => state.user);

  let [oldMobile, setOldMobile] = useState("");
  let password_err = messages.invalidPassword;
  const handleError = useErrorHandler();
  useEffect(() => {
    setLoading(true);

    getDataForVerification(params.string)
      .then((response) => {
        const { statusCode, data } = response;
        if (response.statusCode == 200) {
          setUserData(data);
          setOldMobile(data?.mobile);

          if (user.isAuthorized) {
            if (user.profile.email.toLowerCase() == data.email.toLowerCase()) {
              navigate(`/Signin/${params.string}`);
            } else {
              navigate("/MenuScreen");
            }
          } else {
            if (data.verified_invitee) {
              navigate(`/Signin/${params.string}`);
            }
          }
        } else {
          handleError(response.error);
        }
      })
      .catch((error) => {
        setUserData({});
        console.log("error :>> ", error);
        // navigate("/NotFound");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });
  const [inputError, setInputError] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    isError: false,
    serverError: "",
  });

  const handleSubmit = (values) => {
    let data = {
      ...values,
      name: `${values.name} ${values.lastName}`,
      email: values.email,
      password: values.password,
      mobile: values.phone,
      string: params.string,
      userId: userData.userId,
      contactId: userData.contactId,
    };

    register(data)
      .then((result) => {
        console.log("result :>> ", result);
        navigate("/RegSucessful", { state: { id: result.data } });
      })
      .catch((error) => {
        console.log("error :>> ", error);
        setInputError(error);
      });
  };

  const RegistrationSchema = Yup.object().shape({
    name: Yup.string().required(messages.required.firstName),
    lastName: Yup.string().required(messages.required.lastName),
    email: Yup.string()
      .email(messages.invalidEmail)
      .required(messages.required.email),
    password: Yup.string()
      .required(messages.required.password)
      .matches(passwordRegex, messages.invalidPassword),
    confirmPassword: Yup.string()
      .required(messages.required.confirmPassword)
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    phone: Yup.string()
      .required(messages.required.phone)
      .test({
        name: "validator-custom-name",
        // eslint-disable-next-line object-shorthand
        test: function (value) {
          return inputValidation(value, this);
        },
      })
      .test(
        "is-confirm",
        (d) => `Number Doesn't Match`,
        (value) => (params.string && value ? userData.mobile === value : true)
      ),

    tnc: Yup.boolean().oneOf(
      [true],
      "You must accept the terms and conditions"
    ),
    privacy: Yup.boolean().oneOf(
      [true],
      "Please ensure you have read the Privacy Policy"
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      privacy: false,
    },
    validationSchema: RegistrationSchema,

    onSubmit: (values) => {
      console.log("values fgsfsfsfs:>> ", values);

      const castValues = RegistrationSchema.cast(values);
      handleSubmit(castValues);
    },
    enableReinitialize: true,
  });

  if (isLoading) {
    return <Spinner animation="border" variant="primary" />;
  }
  return (
    <>
      <CustomModel
        show={openTcModal}
        // title={"Term and Condition"}
        title={"Privacy Policy"}
        msg={<div className="tnc-container">{tnc}</div>}
        onHide={() => setOpenTCModal(false)}
        footer={false}
        closeIcon
      />
      <div className="mainPage loginPage">
        {/* <div className="arrowfixed">
          <img src={blackTop} className="arrowleft" alt="" />
        </div> */}
        <div className="text-center">
          <img src={bigPurplePhoneLogo} alt="" />
        </div>

        <div className="customerimg" style={{ paddingTop: "40px" }}>
          <img src={`${userData.photo}`} alt="" />
        </div>

        <div className="contentBody fulogin">
          {params.string ? (
            <h1>
              <span className="fs36">Welcome {userData.invitee_name},</span>
              Register with us to join the Family & Friends of{" "}
              <b>
                {userData.first_name} {userData.last_name}
              </b>
            </h1>
          ) : (
            ""
          )}

          {/* <div className="row">
            <div className="col-lg-6 col">
              <button type="button" className="btn normalBtn w-100">
                Continue With{" "}
                <i>
                  <img src={googlePlusLogin} alt="" />
                </i>
              </button>
            </div>
            <div className="col-lg-6 col">
              <button type="button" className="btn normalBtn w-100">
                Continue With{" "}
                <i>
                  <img src={facebookLogin} alt="" />
                </i>
              </button>
            </div>
          </div>
          <div className="orLine">
            <span>OR</span>
          </div> */}
          <form onSubmit={formik.handleSubmit}>
            <div className="row fmsignup">
              <div className="col-lg-12">
                <h2>Sign up</h2>
                {inputError.serverError.length > 0 && (
                  <span id="span" style={{ color: "red" }}>
                    Email id/Phone No. already exists!
                  </span>
                )}
                <div className="mb-3">
                  {textField(
                    formik,
                    "name",
                    false,
                    "First Name",
                    "name",
                    "text",
                    50,
                    false,
                    true
                  )}
                </div>
                <div className="mb-3">
                  {textField(
                    formik,
                    "lastName",
                    false,
                    "Last Name",
                    "name",
                    "text",
                    50,
                    false,
                    true
                  )}
                </div>
                <div className="mb-3">
                  {textField(
                    formik,
                    "email",
                    false,
                    "Email",
                    "email",
                    "email",
                    50,
                    false,
                    true
                  )}
                </div>
                <div className="mb-3">
                  <PhoneComponent
                    formik={formik}
                    name={"phone"}
                    label={false}
                    placeholder={"Mobile"}
                    icon={"phone"}
                    type="text"
                    required
                  />
                </div>
                <div className="mb-3">
                  <CustomPasswordInput
                    formik={formik}
                    name="password"
                    // label="Create a new Password"
                    placeholder="Create a new password"
                    required
                  />
                </div>
                <small className="fs12">
                  <span className=" pe-2">
                    <img src={infoFeather} alt="" />
                  </span>

                  {messages.invalidPassword}
                </small>
                <div className="mb-3">
                  <CustomPasswordInput
                    formik={formik}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    required
                  />
                </div>
                <br />
                <small className="fs12">
                  <span className="pe-2">
                    <b>*=Required fields</b>
                  </span>
                </small>

                {/* <div className="text-center pt-3 pb-1">
                  <div className="form-group fsbold">
                    <input
                      type="checkbox"
                      id="privacy"
                      name="privacy"
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="privacy">
                      I have read the
                      <a
                        href="https://bigpurplephone.com.au/pages/privacy-policy"
                        target="blank"
                      >
                        <span className="clrblue"> Privacy Policy</span>
                      </a>
                    </label>
                  </div>
                  <div className="error">
                    {formik.errors.privacy ? formik.errors.privacy : ""}
                  </div>
                </div> */}
                <div className="text-center pt-3 pb-1">
                  <div className="form-group fsbold">
                    <input
                      type="checkbox"
                      id="Privacy"
                      name="privacy"
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="Privacy">
                      {/* I agree to the */}I have read the
                      <span
                        className="clrblue"
                        onClick={() => setOpenTCModal(true)}
                      >
                        {" "}
                        Privacy Policy
                      </span>
                    </label>
                  </div>
                  <div className="error">
                    {formik.errors.privacy ? formik.errors.privacy : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <button
                  // disabled={checked}
                  type="submit"
                  className="btn btn-primary w-100"
                // onClick={onClickRegister}
                >
                  Sign Up
                </button>
              </div>
              <p className="text-center pt-5 fsbold">
                Already have an account?{" "}
                <NavLink to="/Signin">
                  <span className="clrblue">
                    <b>Sign In</b>
                  </span>
                </NavLink>
              </p>
            </div>
          </form>
        </div>
        <div className="text-center pb-4 pt-3">
          <img src={bigPurplePhoneLogo} alt="" />
        </div>
      </div>
    </>
  );
}
