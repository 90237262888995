import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import back_white from "../assets/images/back_white.svg";

import TextField from "../components/CustomTextField";

import { updateFAUserDetails } from "../api/api";

import { updateProfileDetails } from "../redux/user";
import UploadProfilePhoto from "components/UploadProfilePhoto";
import { useErrorHandler } from "react-error-boundary";

export default function EditProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [error, setError] = useState({ error: "", isError: false });
  const [state, setState] = useState({
    name: user.profile.name,
    photo: user.profile.photo,
    photoName: "",
  });
  const handleError = useErrorHandler();
  const handleUpload = (photo) => {
    setState({
      ...state,
      photo,
    });
  };

  const onClickUpdate = () => {
    const err = { error: "", isError: false };
    let name = state.name.trim();
    if (name.length === 0) {
      err.error = "Enter name";
      err.isError = true;
    }

    if (err.isError) {
      setError(err);
    } else {
      setError(err);
      let data = new FormData();
      data.append("name", name);

      if (state.photo) {
        data.append("photo", state.photo);
      }

      updateFAUserDetails(data)
        .then((res) => {
          setTimeout(() => {
            let dispatchedData = {
              name: name,
              photo: res.data.data,
            };

            dispatch(updateProfileDetails(dispatchedData));
            navigate("/Profile");
          }, 1900);
        })
        .catch((error) => {
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        });
    }
  };

  return (
    <div className="mainPage">
      <div className="Homeheaderbg text-white" style={{ minHeight: "250px" }}>
        <div className="d-flex align-self-center">
          <NavLink to="/Profile">
            <img src={back_white} alt="" title="" />
          </NavLink>
          <h2 className="fs20 mb-0 text-center mx-auto">Edit Profile</h2>
        </div>
      </div>

      <div className="contentboxgraynotfixedheader">
        <br />
      </div>

      <div className="contentBody contentbodymedia mtminus">
        <UploadProfilePhoto
          prevUrl={user.profile.photo}
          handleUpload={handleUpload}
        />

        <div className="contentBody">
          <div className="row">
            <div className="col-lg-12">
              <div className="mb-3">
                <TextField
                  label="Name"
                  type="name"
                  defaultValue={state.name}
                  onChange={(e) =>
                    setState((prev) => ({ ...prev, name: e.target.value }))
                  }
                />
              </div>
              <span style={{ color: "red" }}>{error.error}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <button
                type="button"
                className="btn btn-primary w-100 mt-3"
                onClick={onClickUpdate}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
