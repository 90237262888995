import React, { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import blackTop from "../assets/images/blackTop.svg";
import loginLogo from "../assets/images/loginLogo.svg";
import bigPurplePhoneLogo from "../assets/images/bigPurpleLogo-dark.svg";
import mobile from "../assets/images/mobile.svg";

import CustomTextField from "../components/CustomTextField";

import { sendOtpMobile } from "../api/api";

import { useFormik } from "formik";
import { PhoneComponent, textField } from "components/InputFields";
import * as Yup from "yup";
import messages from "utils/messages";
import { inputValidation } from "utils/validations";

const phoneRegex = /^[6-9]\d{9}$/;

export default function ForgotPass() {
  const navigate = useNavigate();
  const [number, setNumber] = useState("");
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({ phone: "" });

  const forgotSchema = Yup.object().shape({
    phone: Yup.string()
      .required(messages.required.phone)
      .test({
        name: "validator-custom-name",
        // eslint-disable-next-line object-shorthand
        test: function (value) {
          return inputValidation(value, this);
        },
      }),
  });

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: forgotSchema,

    onSubmit: (values) => {
      const castValues = forgotSchema.cast(values);
      sendOtpMobile({ mobile: castValues.phone })
        .then((result) => {
          navigate("/OtpVerify", { state: { mobile: castValues.phone } });
        })
        .catch((error) => {
          console.log(error);
          // setError("Invalid Number");
        });
    },
    enableReinitialize: true,
  });

  return (
    <div className="mainPage loginPage">
      <div className="headerTop">
        <NavLink to="/Signin" className="backIconTop">
          <img src={blackTop} alt="" />
        </NavLink>
      </div>

      <div className="loginLogo">
        <img src={loginLogo} alt="" />
      </div>

      <div className="contentBody">
        <h1>
          <span>Forgot Password</span>
        </h1>
        <p className="mb-5">
          Please enter your phone number, you will receive a link to create a
          new password via SMS
        </p>

        {/* kldfkldfkldflkdfkldkldf */}

        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-lg-12 mb-4">
              <div className="mb-3">
                <PhoneComponent
                  formik={formik}
                  name={"phone"}
                  placeholder={"Mobile"}
                  icon={"phone"}
                  type="text"
                  label={"Mobile"}
                  required
                />
              </div>
              <div className="">
                <small className="fs12">
                  <span className="pe-2">
                    <b>*=Required fields</b>
                  </span>
                </small>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <button
                // onClick={onClickVerify}
                type="submit"
                className="btn btn-primary w-100 mb-5"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="bigpurplebottom" style={{ textAlign: "center" }}>
        <img src={bigPurplePhoneLogo} alt="" />
      </div>
    </div>
  );
}
