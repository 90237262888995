import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useLoginUser from "../../hooks/useLoginUser";
import { sendVideoRequest } from "../../api/api";
import hangUpIcon from "../../assets/images/hangUp.svg";
import outgoing_ringtone from "../../assets/audio/outgoing_ringtone.mp3";
import { useErrorHandler } from "react-error-boundary";

export default function VideoDial({
  setRoomId,
  is_join,
  handleRejectCall,
  room_id,
}) {
  const { profile } = useLoginUser();
  const navigate = useNavigate();
  const location = useLocation();
  const lovedOne = location.state;

  const [playing, setPlaying] = useState(false);
  const [audio] = useState(new Audio(outgoing_ringtone));
  const handleError = useErrorHandler();
  const playingRef = useRef(playing);
  playingRef.current = playing;
  useEffect(async () => {
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [document]);

  useEffect(async () => {
    let data = {
      caller_role: "users",
      receiver_role: "loved_one",
      receiver_id: lovedOne.id,
    };
    sendVideoRequest(data)
      .then((res) => {
        const { data } = res.data;
        setRoomId(data);
        setPlaying(true);
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  }, []);

  useEffect(() => {
    if (playing) {
      audio.play();
      audio.loop = true;
      setTimeout(() => {
        if (playingRef.current) {
          handleHangupBtn();
        } else {
          console.log("false playing cut call");
        }
        // resolve(true);
      }, 180000);
    } else {
      audio.pause();
      audio.loop = false;
      audio.currentTime = 0;
    }
  }, [playing]);

  const handleHangupBtn = () => {
    setPlaying(false);

    let data = {
      caller_role: "users",
      receiver_role: "loved_one",
      receiver_id: lovedOne.id,
      caller_id: profile?.id,
      room_id,
      type: "caller",
    };

    handleRejectCall(data);
  };

  useEffect(() => {
    if (is_join) {
      setPlaying(false);
    }
  }, [is_join]);
  return (
    <div className={`contentBody pt-5  text-center ${is_join ? "d-none" : ""}`}>
      <div className="callOuterContainer">
        <div className="row justify-content-center">
          <div className="col-8">
            <div className="effect newAnim">
              <span className="callAnimation"></span>
              <img
                src={`${lovedOne?.photo}`}
                className="videocallcircle"
                alt=""
                title=""
              />
            </div>
          </div>
        </div>

        <div className="row justify-content-center pt-5">
          <div className="col-6">
            <img
              className="callImgHeight"
              src={`${profile?.photo}`}
              alt=""
              title=""
            />
          </div>
          <div className="col-12">
            <h3 className="fs30 py-3 mb-0 text-white calltext">
              Video Calling {lovedOne?.first_name}...
            </h3>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <img
              className="callDeclineBtn"
              src={hangUpIcon}
              alt=""
              title=""
              onClick={handleHangupBtn}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
