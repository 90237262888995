import { configureStore } from "@reduxjs/toolkit";
import lovedOne from "./lovedOne";
import user from "./user";

export default configureStore({
    reducer: {
        lovedOne: lovedOne,
        user: user,
    },
});
