import noContactImage from "../assets/images/no-contact1.svg";

export default function NoResultFound({ resultKey }) {
  return (
    <div className="text-center nocontact">
      <img src={noContactImage} style={{ width: "25%" }} alt="no contact" />
      <h3 className="pt-4">No {resultKey} Found</h3>
    </div>
  );
}
