import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

export default function CustomModel({
  title,
  msg,
  onHide,
  show,
  footer = true,
  closeIcon = false,
}) {
  useEffect(() => {
    if (show) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "";
    }
  }, [show]);
  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Header
        closeButton={closeIcon}
        // className={closeIcon ? "adasd" : ""}
      >
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{msg}</Modal.Body>
      {footer ? (
        <Modal.Footer>
          <Button variant="primary" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onHide}>
            Yes
          </Button>
        </Modal.Footer>
      ) : (
        ""
      )}
    </Modal>
  );
}
