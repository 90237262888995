export const tnc = (
  <>
    <p>
      This Privacy Policy describes how bigpurplephone.com (the “Site” or “we”)
      collects, uses, and discloses your Personal Information when you visit the
      Site.
    </p>
    <h1>Collecting Personal Information</h1>
    <p>
      When you visit the Site, we collect certain information about your device
      and your interaction with the Site. We may also collect additional
      information if you contact us for customer support. In this Privacy
      Policy, we refer to any information that can uniquely identify an
      individual (including the information below) as “Personal Information”.
      See the list below for more information about what Personal Information we
      collect and why.
    </p>
    <p>
      <u>Customer support information</u>
    </p>
    <ul>
      <li>
        <strong>Examples of Personal Information collected: </strong>your
        Senior's and your name, names of other contacts in your Senior's Family
        &amp; Friends network, addresses, email address, and phone number.{" "}
      </li>
      <li>
        <strong>Purpose of collection:</strong>
        <span> </span>to provide a link to download the<span> </span>
        <span fragment="1" style={{ color: "#5e2b97" }}>
          <span fragment="1" style={{ fontWeight: "400" }}>
            Big
          </span>
          <b fragment="1">Purple</b>
          <span fragment="1" style={{ fontWeight: "400" }}>
            Phone
          </span>
          <span fragment="1" style={{ fontWeight: "400" }}>
            <sup fragment="1">Ⓡ</sup>
          </span>{" "}
          Family&amp; Friends App
        </span>
        <span fragment="1">, to provide </span>customer service and emergency
        contact, to provide access for you and your family members to th
        <span fragment="1">e </span>
        <span style={{ color: "#5e2b97" }} fragment="1">
          <span style={{ fontWeight: "400" }} fragment="1">
            Big
          </span>
          <b fragment="1">Purple</b>
          <span style={{ fontWeight: "400" }} fragment="1">
            Phone
          </span>
          <span style={{ fontWeight: "400" }} fragment="1">
            <sup fragment="1">Ⓡ</sup>
          </span>
        </span>
      </li>
      <li>
        <strong>Source of collection:</strong>
        <span> </span>collected from you, your Family &amp; Friends network and
        GPS data.
      </li>
      <li>
        <strong>Disclosure for a business purpose:</strong>
        <span> </span>emergency services, shared with our processor Shopify,{" "}
        <span fragment="1">our Telstra wholesale partner, </span>
        <span fragment="1">our</span>
        <span fragment="1">
          {" "}
          logistics and our CRM platform Zoho, GoCardless and Stripe payment
          gateway
        </span>
        <span fragment="1">.</span>
      </li>{" "}
    </ul>
    <p>
      The Site is not intended for individuals under the age of<span> </span>
      <i>13</i>. We do not intentionally collect Personal Information from
      children however, children may be added within the Family&amp;Friends
      network which will include their name, photo and mobile number. If you are
      the parent or guardian and believe your child has provided us with
      Personal Information, please contact us at the address below to request
      deletion.
    </p>
    <ul></ul>
    <h2>Behavioural Advertising</h2>
    <p>
      As described above, we use your Personal Information to provide you with
      targeted advertisements or marketing communications we believe may be of
      interest to you. For example:
    </p>
    <ul>
      <li>
        We use Google Analytics to help us understand how our customers use the
        Site. You can read more about how Google uses your Personal Information
        here:<span> </span>
        <a href="https://policies.google.com/privacy?hl=en" target="_blank">
          https://policies.google.com/privacy?hl=en
        </a>
        .You can also opt-out of Google Analytics here:<span> </span>
        <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">
          https://tools.google.com/dlpage/gaoptout
        </a>
        .
      </li>
      <li>
        We share information about your use of the Site, your purchases, and
        your interaction with our ads on other websites with our advertising
        partners. We collect and share some of this information directly with
        our advertising partners, and in some cases through the use of cookies
        or other similar technologies (which you may consent to, depending on
        your location).
      </li>
    </ul>
    <p>
      For more information about how targeted advertising works, you can visit
      the Network Advertising Initiative's (“NAI”) educational page at
      <span> </span>
      <a
        href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work"
        target="_blank"
      >
        http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
      </a>
      .
    </p>
    <p>You can opt out of targeted advertising by:</p>
    <p>
      <i>
        FACEBOOK -<span> </span>
      </i>
      <a href="https://www.facebook.com/settings/?tab=ads" target="_blank">
        https://www.facebook.com/settings/?tab=ads
      </a>
    </p>
    <p>
      <i>G</i>
      <i>OOGLE - </i>
      <a href="https://www.google.com/settings/ads/anonymous" target="_blank">
        https://www.google.com/settings/ads/anonymous
      </a>
    </p>
    <p>
      Additionally, you can opt out of some of these services by visiting the
      Digital Advertising Alliance's opt-out portal at:<span> </span>
      <a href="http://optout.aboutads.info/" target="_blank">
        http://optout.aboutads.info/
      </a>
      .
    </p>
    <h1>Using Personal Information</h1>
    <p>
      We use your personal Information to provide our services to you, which
      includes:
      <span style={{ backgroundColor: "#ffffff" }}>
        {" "}
        offering products for sale, promotions, processing payments, shipping
        and fulfilment of your order, and{" "}
      </span>
      keeping you up to date on new products, services, and offers.
    </p>
    <ul></ul>
    <h2>
      <span style={{ backgroundColor: "#ffff00" }}></span>
    </h2>
    <p>
      <span style={{ fontSize: "1.4em" }}>
        <strong>Automatic decision-making</strong>
      </span>
    </p>
    <p>
      We<span> </span>
      <i>DO NOT</i> engage in fully automated decision-making that has a legal
      or otherwise significant effect using customer data.
    </p>
    <p>
      Our processor Shopify uses limited automated decision-making to prevent
      fraud that does not have a legal or otherwise significant effect on you.
    </p>
    <p>Services that include elements of automated decision-making include:</p>
    <ul>
      <li>
        Temporary denylist of IP addresses associated with repeated failed
        transactions. This denylist persists for a small number of hours.
      </li>
      <li>
        Temporary denylist of credit cards associated with denylisted IP
        addresses. This denylist persists for a small number of days.
      </li>
    </ul>
    <ul></ul>
    <h1></h1>
    <h2>
      <span style={{ backgroundColor: "#ffffff" }}>Cookies</span>
    </h2>
    <p>
      <span style={{ backgroundColor: "#ffffff" }}>
        A cookie is a small amount of information that's downloaded to your
        computer or device when you visit our Site. We use a number of different
        cookies, including functional, performance, advertising, and social
        media or content cookies. Cookies make your browsing experience better
        by allowing the website to remember your actions and preferences (such
        as login and region selection). This means you don't have to re-enter
        this information each time you return to the site or browse from one
        page to another. Cookies also provide information on how people use the
        website, for instance whether it's their first time visiting or if they
        are a frequent visitor.
      </span>
    </p>
    <p>
      The length of time that a cookie remains on your computer or mobile device
      depends on whether it is a “persistent” or “session” cookie. Session
      cookies last until you stop browsing and persistent cookies last until
      they expire or are deleted. Most of the cookies we use are persistent and
      will expire between 30 minutes and two years from the date they are
      downloaded to your device.
    </p>
    <p>
      You can control and manage cookies in various ways. Please keep in mind
      that removing or blocking cookies can negatively impact your user
      experience and parts of our website may no longer be fully accessible.
    </p>
    <p>
      Most browsers automatically accept cookies, but you can choose whether or
      not to accept cookies through your browser controls, often found in your
      browser's “Tools” or “Preferences” menu. For more information on how to
      modify your browser settings or how to block, manage or filter cookies can
      be found in your browser's help file or through such sites as
      <span> </span>
      <a
        href="https://bigpurplephone.myshopify.com/admin/settings/www.allaboutcookies.org"
        target="_blank"
      >
        www.allaboutcookies.org
      </a>
      .
    </p>
    <p>
      Additionally, please note that blocking cookies may not completely prevent
      how we share information with third parties such as our advertising
      partners. To exercise your rights or opt-out of certain uses of your
      information by these parties, please follow the instructions in the
      “Behavioural Advertising” section above.
    </p>
    <h2>Do Not Track</h2>
    <p>
      Please note that because there is no consistent industry understanding of
      how to respond to “Do Not Track” signals, we do not alter our data
      collection and usage practices when we detect such a signal from your
      browser.
    </p>
    <h2>Changes</h2>
    <p>
      We may update this Privacy Policy from time to time in order to reflect,
      for example, changes to our practices or for other operational, legal, or
      regulatory reasons.
    </p>
    <h1>Contact</h1>
    <p>
      For more information about our privacy practices, if you have questions,
      or if you would like to make a complaint, please contact us by e-mail at{" "}
      <strong>support@bigpurplephone.com.au</strong>
      <span> </span>or by mail using the details provided below:
    </p>
    <p>10 Walker Street, Bowral NSW 2576</p>
    <p>
      Last updated:<span> 17th</span> April 2023
    </p>
    <p> </p>
    <p>
      If you are not satisfied with our response to your complaint, you have the
      right to lodge your complaint with the relevant data protection authority.
      You can contact your local data protection authority, or our supervisory
      authority here:{" "}
      <a
        href="https://www.oaic.gov.au/privacy/privacy-complaints"
        target="blank"
      >
        https://www.oaic.gov.au/privacy/privacy-complaints
      </a>
    </p>
  </>
);
