import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useDebounce } from "use-debounce";

import back_white from "../assets/images/back_white.svg";
import userSmall from "../assets/images/user-small.svg";
import { useSelector } from "react-redux";

import { getFAContactList, getRecentCall } from "../api/api";
import AutoSuggest from "../components/AutoSuggest";
import NoResultFound from "../components/NoResultFound";
import { useErrorHandler } from "react-error-boundary";

export default function Contacts({ page = false }) {
  const [list, setList] = useState([]);
  const [state, setState] = useState("");
  const [value] = useDebounce(state, 1000);
  const [isLoading, setLoading] = useState(false);
  const [showList, setShowList] = useState("");
  const [showRecentList, setShowRecentList] = useState("");
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const handleError = useErrorHandler();
  useEffect(() => {
    setLoading(true);

    getFAContactList(value)
      .then((result) => {
        setList(result);
        setShowList(result);
      })
      .catch((error) => {
        setList([]);
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      })
      .finally(() => {
        setLoading(false);
      });
  }, [value]);

  useEffect(() => {
    if (page && page == "video") {
      if (user.profile) {
        let params = {
          caller_role: "users",
          caller_id: user.profile.id,
        };
        getRecentCall(params)
          .then((res) => {
            setShowRecentList(res.data.data);
          })
          .catch((error) => {
            return error.response.data.statusCode != 400
              ? handleError(error)
              : "";
          });
      }
    }
  }, []);

  const getList = (updated_list) => {
    setShowList(updated_list);
  };

  const getContactList = (type = "contacts") => {
    if (isLoading) {
      return <Spinner animation="border" variant="primary" />;
    }
    let c_list = type == "contacts" ? showList : showRecentList;
    if (c_list.length) {
      return c_list.map((item, i) => (
        <div
          key={i}
          className="media boxmediawhite"
          onClick={() => {
            if (page && page === "video") {
              navigate("/VideoCall", { state: item });
            } else {
              return true;
            }
          }}
        >
          <img
            className="align-self-center"
            src={item.photo ? `${item.photo}` : userSmall}
            alt=""
            title=""
          />
          <div className="media-body">
            <h5 className="mt-0 mb-0">
              {item.name
                ? item.name
                : `${item.first_name} ${item.last_name || ""}`}
            </h5>
          </div>
        </div>
      ));
    } else {
      return page ? "No Contacts" : <NoResultFound resultKey={"contacts"} />;
    }
  };

  return (
    <div className="pt80">
      <div className="mainPage">
        <div className="Homeheaderbg headerfixed text-white">
          <div className="d-flex align-self-center">
            <NavLink to="/MenuScreen">
              <img src={back_white} alt="" title="" />
            </NavLink>

            <h2 className="fs20 mb-0 text-center mx-auto">Contacts</h2>
          </div>
        </div>
        <div className="parent">
          <div className="contentboxgray11">
            <br />
          </div>
        </div>
        <div className="contentBody contentbodymedia">
          <div className="contentBody">
            <div className="row justify-content-center">
              {page && page == "video" ? (
                ""
              ) : (
                <div className="searchbar">
                  <div className="form-outline">
                    <AutoSuggest data={list} getList={getList} />
                  </div>
                </div>
              )}

              {page && page == "video" ? (
                <>
                  <h3>Recent Calls</h3>
                  {getContactList("recent")}
                </>
              ) : (
                ""
              )}

              <h3>Contacts</h3>
              {getContactList()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
