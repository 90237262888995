import React, { useEffect } from "react";
import bppHelpIcon from "assets/images/bpp-help.png";

import Layout from "components/Layout";
export default function ContactUs() {
  return (
    <div className="pt80">
      <Layout
        headerText={"Get <b>Help</b>"}
        headerName=""
        backNavigate={"/MenuScreen"}
      >
        <div className="parent">
          <div className="contentboxgray11">
            <br />
          </div>
        </div>

        <div className="contentBody contentbodymedia setTopSpace">
          <div className="contentBody">
            <h2 className="mb-4 mt-4 pageleftHeading">
              Contact <b>Us</b>
            </h2>
            <p className="mb-4 contactInfos">
              Chat with us to get help by clicking on the Help icon, call us on{" "}
              <a href="tel:+61286601390" className="skylink">
                (02) 8660-1390
              </a>{" "}
              or email us at{" "}
              <a
                href="mailto:support@bigpurplephone.com.au"
                // className="emailtext"
              >
                support@bigpurplephone.com.au
              </a>
            </p>
            <p className="mb-4 contactInfos">
              <a href="https://bigpurplephone.com.au/" target="blank">
                www.big<b>purple</b>phone.com.au
              </a>
            </p>
            <div className="help-support">
              <a href="tel:+61286601390" className="">
                <img src={bppHelpIcon} className="cont" />
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
