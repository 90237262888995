import footerBPPLogo from "assets/images/bigpurple-logo.svg";
export default function Footer() {
  return (
    <div className="bottomLogo-color">
      <a href="#">
        <img src={footerBPPLogo} alt="footerBPPLogo" />
      </a>
    </div>
  );
}
