import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useNavigate, NavLink } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import myProfile from "assets/images/my-profile.svg";
import tick from "assets/images/tick.svg";
import changePasswosrd from "assets/images/changePasswosrd.svg";
import listRightArrow from "assets/images/listRightArrow.svg";
import {
  getUserDetail,
  sendOtpUpdateMobile,
  updateFAUserDetails,
} from "../api/api";
// import CustomTextField from "components/CustomTextField";
// import { PhoneComponent } from "components/InputFields";
import { useErrorHandler } from "react-error-boundary";
import Layout from "components/Layout";
import UploadProfilePhoto from "components/UploadProfilePhoto";
import { updateProfileDetails } from "redux/user";
import { useFormik } from "formik";
import { PhoneComponent, textField } from "components/InputFields";
import * as Yup from "yup";
import messages from "utils/messages";
import { inputValidation } from "utils/validations";
import "assets/scss/profile.scss";
import { toast } from "react-toastify";

export default function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [photo, setPhoto] = useState(null);

  const [data, setData] = useState("");
  const [isLoading, setLoading] = useState(false);
  const handleError = useErrorHandler();
  useEffect(() => {
    setLoading(true);
    getUserDetail()
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const onClickReset = () => {
    navigate("/ChangePassword");
  };

  const handleSubmitProfile = (formData) => {
    updateFAUserDetails(formData)
      .then((res) => {
        let dispatchedData = {
          name: formik.values.name,
          photo: res.data.data,
          email: formik.values.email,
        };

        dispatch(updateProfileDetails(dispatchedData));
        setData({
          ...data,
          name: formik.values.name,
          email: formik.values.email,
        });
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };
  const handleUpload = (photo) => {
    setPhoto(photo)
    // let formData = new FormData();
    // formData.append("name", data.name);
    // formData.append("photo", photo);
    // formData.append("email", data.email);

    // // updateFAUserDetails(formData)
    // //   .then((res) => {
    // //     let dispatchedData = {
    // //       name: formData.name,
    // //       photo: res.data.data,
    // //     };

    // //     dispatch(updateProfileDetails(dispatchedData));
    // //   })
    // //   .catch((error) => {
    // //     return error.response.data.statusCode != 400 ? handleError(error) : "";
    // //   });
    // handleSubmitProfile(formData);
  };

  const ProfileSchema = Yup.object().shape({
    name: Yup.string().required(messages.required.firstName),
    email: Yup.string()
      .email(messages.invalidEmail)
      .required(messages.required.email),

    phone: Yup.string()
      .required(messages.required.phone)
      .test({
        name: "validator-custom-name",
        // eslint-disable-next-line object-shorthand
        test: function (value) {
          return inputValidation(value, this);
        },
      }),
  });
  const formik = useFormik({
    initialValues: {
      name: data.name,
      email: data.email,
      phone: data.mobile,
    },
    validationSchema: ProfileSchema,

    onSubmit: (values) => {
      const castValues = ProfileSchema.cast(values);

      if (data.mobile != castValues.phone) {
        console.log("error");

        formik.setErrors({ phone: "Please Verify your phone Number" });
      } else {
        let formData = new FormData();
        formData.append("name", castValues.name);
        formData.append("email", castValues.email);
        if (photo) {
          formData.append("photo", photo)
        }
        handleSubmitProfile(formData);
      }
    },
    enableReinitialize: true,
  });

  const handleVerify = async () => {
    if (formik.values.phone != data.mobile) {
      await sendOtpUpdateMobile({ mobile: formik.values.phone }).then((res) => {
        navigate("/OTPVerify", {
          state: { mobile: formik.values.phone, type: "updateMobile" },
        });
      });
    }
  };
  const handleNavigate = () => {
    let objData = {
      name: data.name,
      email: data.email,
      phone: data.mobile,
    };
    let isEqual =
      Object.entries(objData).toString() ===
      Object.entries(formik.values).toString();

    if (isEqual) {
      navigate("/MenuScreen");
    } else {
      toast('Please select "Submit" to save your changes', {
        type: "error",
      });
    }
  };
  return (
    <Layout
      headerText={"My <b>Profile</b>"}
      headerName=""
      headerClass="headerHeight text-white"
      backNavigate={"/MenuScreen"}
      handleNavigate={handleNavigate}
      // backNavigate={false}
      hidePhoto
      noFixedHeader
    >
      <div className="contentboxgraynotfixedheader">
        <br />

        {isLoading ? (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="contentBody contentbodymedia mtminus">
            <UploadProfilePhoto
              prevUrl={data.photo ? data.photo : myProfile}
              handleUpload={handleUpload}
            />

            <h3 className="text-center pb-2 pt-3 username">{data.name}</h3>
            <div className="contentBody">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3 mt-4">
                      {textField(formik, "name", false, "Name *", "name")}
                    </div>

                    <div className="mb-3 position-relative">
                      {formik.values.phone != data.mobile ? (
                        <button
                          className="verifyPhone"
                          onClick={handleVerify}
                          type="button"
                        >
                          Verify Now
                        </button>
                      ) : (
                        <img src={tick} className="verifyPhone" />
                      )}
                      <PhoneComponent
                        formik={formik}
                        name={"phone"}
                        // label={"Mobile"}
                        // placeholder={"Mobile"}
                        icon={"phone"}
                        type="text"
                        required
                      />
                    </div>
                    <div className="mb-3 mt-4">
                      {textField(
                        formik,
                        "email",
                        false,
                        "Email Address *",
                        "email"
                      )}
                    </div>
                    <div className="">
                      <small className="fs12">
                        <span className="pe-2">
                          <b>*=Required fields</b>
                        </span>
                      </small>
                    </div>
                    <div className=" mt-4">
                      <button type="submit" className="btn btn-primary w-100">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              <div className="row">
                <div className="col-lg-12">
                  <button
                    type="button"
                    onClick={onClickReset}
                    className="btn btn-primary w-100 passwordbtn"
                  >
                    <img
                      src={changePasswosrd}
                      className="me-3"
                      alt=""
                      title=""
                    />
                    Change Password
                    <span className="iconEye" style={{ top: "7px" }}>
                      <img src={listRightArrow} alt="" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}
