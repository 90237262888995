import React from "react";

import user from "../assets/images/user.svg";
import loginMail from "../assets/images/loginMail.svg";
import mobile from "../assets/images/mobile.svg";
import calendar from "../assets/images/calendar.svg";

function InputFieldImg({ type }) {
  switch (type) {
    case "name":
      return <img src={user} alt="" title="" />;
    case "email":
      return <img src={loginMail} alt="" title="" />;
    case "phone":
      return <img src={mobile} alt="" title="" />;
    case "dob":
      return <img src={calendar} alt="" title="" />;
    default:
      return null;
  }
}

// export default function CustomTextField({
//   type,
//   onChange,
//   value,
//   placeholder,
//   label,
//   ...restValues
// }) {
//   return (
//     <>
//       {label ? (
//         <label htmlFor="" className="form-label">
//           {label}
//         </label>
//       ) : null}
//       <div className="inputIcon">
//         {/* <span className={`${type == "phone" ? "country-prefix" : "d-none"}`}>
//           +61
//         </span> */}
//         <input
//           {...restValues}
//           value={value}
//           onChange={onChange}
//           style={{ color: "black" }}
//           type="text"
//           className={`form-control ${
//             type == "phone" ? "iconInputPhoneLeft" : "iconInputLeft"
//           }`}
//           placeholder={placeholder}
//         />
//         <span className="icons">
//           <InputFieldImg type={type} />
//         </span>
//       </div>
//     </>
//   );
// }

export default function CustomTextField({
  type,
  onChange,
  value,
  placeholder,
  label,
  ...rest
}) {
  let input_value = value;

  return (
    <>
      {label ? (
        <label htmlFor="" className="form-label">
          {label}
        </label>
      ) : null}
      <div className="inputIcon">
        <input
          {...rest}
          value={input_value ? input_value : value}
          onChange={onChange}
          style={{ color: "black" }}
          type="text"
          className="form-control iconInputLeft"
          placeholder={placeholder}
          autoComplete={false}
        />
        <span className="icons">
          <InputFieldImg type={type} />
        </span>
      </div>
    </>
  );
}
