import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function NonAuthorizedSplash() {
  const navigate = useNavigate();

  useEffect(() => {
    window.location = "/Signin";
    // Please don't remove this comment. as per client request currently we comment the set timeout function
    // setTimeout(() => {
    //   // navigate("/Signin");
    //   window.location = "/Signin";
    // }, 11000);
  });

  return (
    <div className="mainPage ">
      {/* // Please don't remove this comment. as per client request currently we comment the set timeout function */}
      {/* <div className="headerOuter">
        <video
          autoPlay
          muted
          loop
          id="myVideo"
          className="videoOuter"
          playsInline
          webkit-playsinline
          src="/video_splash.mp4"
          type="video/mp4"
        ></video>
      </div> */}
    </div>
  );
}
