import { createSlice } from "@reduxjs/toolkit";

export const lovedOne = createSlice({
  name: "lovedOne",
  initialState: { value: {} },
  reducers: {
    changeLovedOne: (state, action) => {
      state.value = action.payload;
      sessionStorage.setItem("bpp_lovedOne", JSON.stringify(action.payload));
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeLovedOne } = lovedOne.actions;

export default lovedOne.reducer;
