import React from "react";
import { useSelector } from "react-redux";

import AuthorizedSplash from "./AuthorizedSplash";
import NonAuthorizedSplash from "./NonAuthorizedSplash";

export default function SplashScreen() {
  const user = useSelector((state) => state.user);

  if (user.isAuthorized) {
    return <AuthorizedSplash />;
  } else {
    return <NonAuthorizedSplash />;
  }
}
