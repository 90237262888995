import { logout } from "api/api";
import login_error from "assets/images/logo_404.svg";
import { useEffect } from "react";
// import back_icon from "assets/images/back_white.svg";
import { useNavigate } from "react-router-dom";

export default function NotFound({ serverError, resetErrorBoundary, error }) {
  const navigate = useNavigate();
  // let statusCode = ["401", "400", "500"];

  useEffect(() => {
    if (serverError) {
      if (
        (error && error.statusCode == "401") ||
        error.error == "invalid token" ||
        error.error == "jwt expired" ||
        error.error == "JsonWebTokenError" ||
        error.error == "jwt malformed" ||
        error.error == "Invalid JWT"
      ) {
        if (localStorage.getItem("bpp_profile")) {
          logout().then((res) => {});
        }
        localStorage.removeItem("bpp_profile");
        localStorage.removeItem("bpp_jwt_token");
        window.location.assign("/");
      }
    }
  }, []);

  return (
    <div className="mainPage">
      <div className="contentboxgraynotfixedheader">
        <br />
      </div>

      <div className="contentBody contentbodymedia mtminus">
        <div className="contentBody fulogin">
          <div className="row fmsignup mt-2">
            <div className="col-lg-12 mb-5 text-center mt-5">
              <a href="#">
                <img src={login_error} />
              </a>
              {serverError ? (
                error && error.statusCode == "403" ? (
                  <h4 style={{ fontSize: "72px", margin: "35px 0" }}>
                    Link has been expired.
                  </h4>
                ) : (
                  <>
                    <h4 style={{ margin: "35px 0" }}>
                      Please check your internet connection.
                      {/* Our server is currently down. We apologise for the
                    inconvenience. */}
                      Or Please click Try again in a few moments. If the problem
                      persists, please call us on{" "}
                      <a href="tel:+61286601390">(02) 8660 1390</a>
                    </h4>
                  </>
                )
              ) : (
                <>
                  <h2 style={{ fontSize: "72px", margin: "35px 0" }}>404</h2>

                  <h4>Page Not Found</h4>

                  <p>Sorry, we can't find the page you're looking for.</p>
                </>
              )}
            </div>
          </div>

          <div className="row">
            <div className="d-flex mt-3 mb-3">
              {serverError &&
              error &&
              error.statusCode != "403" &&
              error.statusCode != "401" ? (
                <button
                  type="button"
                  className="btn btn-primary w-100  "
                  onClick={() => resetErrorBoundary()}
                >
                  Try again
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary w-100  "
                  onClick={() => navigate("/")}
                >
                  Back To Home
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
