import axios from "axios";
import { setToken, getToken, setProfile } from "../utils/localStorage";
import { toast } from "react-toastify";
import { baseUrl } from "utils/service";
import { wrapper } from "utils/helpers";

import { GET, PUT, POST, DELETE } from "./request";

const header = {};

export const login = async (data) => {
  try {
    const response = await axios.post(`${baseUrl}/auth/login`, data);
    setToken(response.data.data.token);
    setProfile(response.data.data);
    return response;
  } catch (error) {
    toast(error.response.data.message, { type: "error" });
    throw new Error("Login error");
  }
};

export const register = async (body) => {
  try {
    const response = await axios.post(`${baseUrl}/auth/register`, {
      name: body.name,
      email: body.email,
      mobile: body.mobile,
      password: body.password,
      string: body.string,
      userId: body.userId,
      contactId: body.contactId,
    });
    // toast(response.data.message, { type: "success" });

    return response;
  } catch (error) {
    toast(error.response.data.message, { type: "error" });

    throw new Error("Registraion error");
  }
};

export const readPWA = async () => {
  return wrapper(async () => {
    const response = await PUT(`/user/editPwaPopup`);
    return response.data.data;
  });
};
export const sendOtpMobile = async (data) => {
  try {
    const response = await POST(`/user/forgetPasswordMobile`, {
      data,
    });
    toast(response.data.message, { type: "success" });

    return response;
  } catch (error) {
    toast(error.response.data.message, { type: "error" });

    throw new Error("Otp error");
  }
};

export const sendOtpUpdateMobile = async (data) => {
  return wrapper(async () => {
    const response = await POST(`/user/sendMobileVerifyOtp`, { data });
    toast(response.data.message, { type: "success" });
    return response.data.data;
  }, true);
};

export const verifyOtpUpdateMobile = async (data) => {
  return wrapper(async () => {
    const response = await PUT(`/user/verifyNewMobileOtp`, { data });
    return response.data.data;
  }, true);
};

export const searchLovedOne = async (search = "") => {
  header.authorization = `Bearer ${getToken()}`;

  try {
    const response = await axios.get(`/user/fmLovedOneList`, {
      headers: header,
    });
    return response.data;
  } catch (error) {
    console.log("error >>>>>>>", error);

    throw error;
  }
};

export const verifyOtpMobile = async (otp, mobile) => {
  try {
    const response = await axios.put(`${baseUrl}/user/verifyOtp`, {
      mobile,
      otp,
    });
    return response;
  } catch (error) {
    throw new Error("Verify otp error");
  }
};

export const resetPassword = async (password, mobile) => {
  try {
    const response = await axios.put(`${baseUrl}/user/resetPassword`, {
      mobile,
      password,
    });
    return response;
  } catch (error) {
    throw new Error("Change password error");
  }
};

// export const getUserDetail = async () => {
//   header.authorization = `Bearer ${getToken()}`;
//   try {
//     const response = await axios.get(`${baseUrl}/user/getUserDetail`, {
//       headers: header,
//     });

//     return response.data.data;
//   } catch (error) {
//     throw error;
//   }
// };

export const changeFAPassword = async (oldPassword, newPassword) => {
  try {
    header.authorization = `Bearer ${getToken()}`;

    const response = await axios.put(
      `${baseUrl}/user/profileChangePassword`,
      { oldPassword: oldPassword, password: newPassword },
      { headers: header }
    );
    return response.data.data;
  } catch (error) {
    toast(error.response.data.message, { type: "error" });
    throw error;
  }
};

export const updateFAUserDetails = async (data) => {
  header.authorization = `Bearer ${getToken()}`;

  try {
    const response = await axios.put(`${baseUrl}/user/editProfile`, data, {
      headers: header,
    });

    console.log("response.data.message", response.data)
    toast('Profile details successfully updated', { type: "success" });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getFAContactList = async (search = "") => {
  header.authorization = `Bearer ${getToken()}`;

  try {
    const response = await axios.get(
      `${baseUrl}/user/familyAdminContactList?search=${search}`,
      { headers: header }
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getDataForVerification = async (string) => {
  try {
    const response = await axios.get(
      `${baseUrl}/user/getDataForVerification?verify_string=${string}`
    );
    return response.data;
  } catch (error) {
    return { error, statusCode: 400 };
  }
};

// export const getDataForVerification = async (string) => {
//   return wrapper(async () => {
//     const response = await GET(
//       `/user/getDataForVerification?verify_string=${string}`
//     );
//     return response.data;
//   });
// };
export const updateVerify = async (data) => {
  try {
    const response = await axios.put(`${baseUrl}/user/updateVerify`, data);
    return response.data.data;
  } catch (error) {
    return { error };

    // toast(error.response.data.message, { type: "error" });
    // throw error;
  }
};
export const addFMProfilePhoto = async ({ photo, photoName, id }) => {
  try {
    header.authorization = `Bearer ${getToken()}`;

    const response = await axios.post(
      `${baseUrl}/user/addProfilePhoto`,
      { photo, photoName, id },
      { headers: header }
    );
    console.log("response", response);
    return response;
  } catch (error) {
    console.log(error.response.data.message, "erorrrrrrrrrrrr");
    toast(error.response.data.message, { type: "error" });
    throw new Error(error.response.data.message);
  }
};
// =======================================Photos=============================================

export const FMgetGallaryPhotos = async ({ lovedOneId }) => {
  header.authorization = `Bearer ${getToken()}`;

  try {
    const response = await axios.get(`${baseUrl}/gallery/pwaGalleryList`, {
      headers: header,
      params: { lovedOneId },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getGallaryPhotoById = async (params) => {
  header.authorization = `Bearer ${getToken()}`;

  try {
    const response = await axios.get(`${baseUrl}/gallery/getPhotoById`, {
      headers: header,
      params,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const deleteFMphotos = async (data) => {
  header.authorization = `Bearer ${getToken()}`;

  try {
    const response = await axios.put(`${baseUrl}/gallery/deletePhoto`, data, {
      headers: header,
    });
    toast(response.data.message, { type: "success" });
    return response;
  } catch (error) {
    toast(error.response.data.message, { type: "error" });

    throw error;
  }
};

export const likeDislikeFMphotos = async ({ id, isLike, role }) => {
  header.authorization = `Bearer ${getToken()}`;

  try {
    const response = await axios.put(
      `${baseUrl}/gallery/photoLikeDislike`,
      { id, isLike, role },
      { headers: header }
    );

    return response;
  } catch (error) {
    throw error;
  }
};
export const addPhotos = async (data) => {
  try {
    header.authorization = `Bearer ${getToken()}`;
    const response = await axios.post(`${baseUrl}/gallery/addPhoto`, data, {
      headers: header,
    });
    toast(response.data.message, { type: "success" });

    return response;
  } catch (error) {
    toast(error.response.data.message, { type: "error" });

    throw error;
  }
};
export const sharePhotos = async (data) => {
  try {
    header.authorization = `Bearer ${getToken()}`;
    const response = await axios.post(`${baseUrl}/gallery/sharePhoto`, data, {
      headers: header,
    });
    toast(response.data.message, { type: "success" });

    return response;
  } catch (error) {
    toast(error.response.data.message, { type: "error" });

    throw error;
  }
};

//#region set device token

export const setDeviceToken = async (body) => {
  header.authorization = `Bearer ${getToken()}`;

  try {
    const response = await axios.put(`${baseUrl}/user/setDeviceToken`, body, {
      headers: header,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

//#endregion

//#region video call
export const getFullUrl = async (shortUrl) => {
  return wrapper(async () => {
    const response = await GET(`/videoCall/getFullUrl?short_url=${shortUrl}`);
    return response.data.data;
  });
};
export const sendVideoRequest = async (data) => {
  try {
    header.authorization = `Bearer ${getToken()}`;
    const response = await axios.post(
      `${baseUrl}/videoCall/addVideoCall`,
      data,
      { headers: header }
    );
    return response;
  } catch (error) {
    toast(error.response.data.message, { type: "error" });
    throw error;
  }
};

export const rejectCall = async (data) => {
  try {
    header.authorization = `Bearer ${getToken()}`;
    const response = await axios.post(
      `${baseUrl}/videoCall/notifyCallReject`,
      data,
      {
        headers: header,
      }
    );
    return response;
  } catch (error) {
    toast(error.response.data.message, { type: "error" });

    throw error;
  }
};

export const notifyLoRejectCall = async (data) => {
  return wrapper(async () => {
    const response = await POST(`/videoCall/notifyCallHangup`, { data });
    // toast(response.data.data, { type: "success" });
    return response;
  }, true);
};

export const callStatus = async (params) => {
  try {
    header.authorization = `Bearer ${getToken()}`;
    const response = await axios.get(`${baseUrl}/videoCall/callStatus`, {
      headers: header,
      params,
    });

    return response;
  } catch (error) {
    toast(error.response.data.message, { type: "error" });
    throw error;
  }
};

export const getRecentCall = async (params) => {
  try {
    header.authorization = `Bearer ${getToken()}`;
    const response = await axios.get(`${baseUrl}/videoCall/recentCalls`, {
      headers: header,
      params,
    });

    return response;
  } catch (error) {
    toast(error.response.data.message, { type: "error" });

    throw error;
  }
};
//#endregion

export const getLovedOneById = async (params) => {
  try {
    header.authorization = `Bearer ${getToken()}`;
    header.id = params.lovedOneId;
    const response = await axios.get(`${baseUrl}/user/lovedOneProfile`, {
      headers: header,
      params,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const logout = async () => {
  try {
    header.authorization = `Bearer ${getToken()}`;
    const response = await axios.post(
      `${baseUrl}/auth/logout`,
      { role: "fm" },
      {
        headers: header,
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getUserDetail = async (params) => {
  return wrapper(async () => {
    const response = await GET(`/user/getUserDetail?module=fm`, {
      params,
    });
    return response.data.data;
  });
};
