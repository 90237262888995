import Footer from "./Footer";
import Header from "./Header";

export default function Layout({
  headerName,
  children,
  backNavigate = false,
  headerText = "",
  headerClass = "",
  hidePhoto = false,
  noFixedHeader = false,
  handleNavigate = false,
}) {
  return (
    <div className="mainPage">
      <Header
        headerName={headerName}
        backNavigate={backNavigate}
        headerText={headerText}
        headerClass={headerClass}
        hidePhoto={hidePhoto}
        noFixedHeader={noFixedHeader}
        handleNavigate={handleNavigate}
      />
      {children} <Footer />
    </div>
  );
}
