import React, { useState } from "react";
import loginPassword from "../assets/images/loginPassword.svg";
import loginEyeOff from "../assets/images/loginEyeOff.svg";
import loginEye from "../assets/images/loginEye.svg";

export default function PasswordInput({
  name,
  label,
  formik,
  placeholder,
  required,
}) {
  const [hidden, setHidden] = useState(true);
  const { errors, values, touched } = formik;

  return (
    <>
      {label ? (
        <label htmlFor="" className="form-label">
          {label} {required ? <span className="error">*</span> : ""}
        </label>
      ) : null}
      <div className="inputIcon">
        <input
          name={name}
          onChange={formik.handleChange}
          type={hidden ? "password" : "text"}
          className="form-control iconInputLeft"
          placeholder={`${placeholder ? placeholder : "Password"} ${
            label ? "" : required ? "*" : ""
          }`}
          value={formik.values[name]}
        />
        <span className="icons">
          <img src={loginPassword} alt="" title="" />
        </span>

        <span className="iconEye" onClick={() => setHidden(!hidden)}>
          <img src={hidden ? loginEyeOff : loginEye} alt="" title="" />
        </span>
      </div>
      {errors[name] && touched[name] ? (
        <div className="error">{errors[name]}</div>
      ) : null}
    </>
  );
}
