import React, { useEffect, useState } from "react";
import uploadCertifcate from "../assets/images/uploadCertifcate.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { addFMProfilePhoto } from "../api/api";
import { imgInputFilePicker } from "../utils/utils";
import { toast } from "react-toastify";
import { useErrorHandler } from "react-error-boundary";

export default function PopPhoto() {
  const location = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const handleError = useErrorHandler();
  const onClickPicBtn = async () => {
    try {
      const imgData = await imgInputFilePicker();

      setFormData((prev) => ({
        photo: imgData.base64,
        photoName: imgData.name,
      }));
    } catch (e) {
      if (typeof e == "undefined")
        toast("Please select only image", { type: "error" });
      else toast(e, { type: "error" });
    }
  };
  const onClickUpload = () => {
    if (formData && formData.photo) {
      addFMProfilePhoto({
        id: location.state,

        ...formData,
      })
        .then((result) => {
          navigate("/PhotoUploadSucc");
        })
        .catch((error) => {
          toast(error, { type: "error" });
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        });
    } else {
      toast("Select a photo", { type: "error" });
    }
  };

  return (
    <div className="mainPage">
      <div className="contentBody contentboxgray text-center positioncenter">
        <div className="contentbodypadd pt-4">
          <div className="col-md-12">
            <div className="popupbox">
              <div className="popcontent">
                <div className="contentbodypadd p-0">
                  <div className="col-md-12">
                    <a onClick={onClickPicBtn}>
                      {formData.photo ? (
                        <img
                          className="uploadedPhotoOuter"
                          src={`data:image/jpeg;base64,${formData.photo}`}
                          alt=""
                          title=""
                        />
                      ) : (
                        <div className="uploadbox">
                          <img src={uploadCertifcate} alt="" title="" />{" "}
                          <p className="mb-0">Upload Photo</p>
                        </div>
                      )}
                    </a>
                  </div>
                  {/* {img ? "Please select image" : ""} */}
                </div>
              </div>
              <div className="d-flex justify-content-center">
                {/* <div className="col text-center">
                                    <NavLink to="/Signin">
                                        <button type="button" className="btn btn-primary btnwidoutbg btn1">
                                            Cancel
                                        </button>
                                    </NavLink>
                                </div> */}
                <div className="col text-center pb-3">
                  <button
                    onClick={onClickUpload}
                    type="button"
                    className="btn btn-primary px-4"
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
