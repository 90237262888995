import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import photoIcon from "assets/images/photoicon.png";
import videoIcon from "assets/images/videoicon.png";
import phoneIcon from "assets/images/phoneicon.png";
import messageIcon from "assets/images/messageicon.png";
import helpIcon from "assets/images/helpicon.png";
import "assets/css/body.css";
import Layout from "components/Layout";
import useSelectedLovedOne from "hooks/useSelectedLovedOne";
import IphonePWAPopup from "components/IphonePWAPopup";
import { getUserDetail } from "api/api";
import { getUncheckedPhotoCount } from "api/gallery";
import "assets/scss/lovedOneList.scss";

export default function MenuScreen() {
  const [loProfile, setLoProfile] = useState({});
  const lovedOne = useSelectedLovedOne();
  const [menu_text, setMenuText] = useState();
  const [menu_list, setMenuList] = useState();
  const [uncheckedPhoto, setUncheckedPhoto] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  // Get the current date and time
  const now = new Date();

  // Define the date and time for the maintenance window
  const maintenanceStart = new Date('2024-11-14T00:01:00'); // 00:01 PM on Nov 14th
  const maintenanceEnd = new Date('2024-11-14T23:59:59'); // 23:59 PM on Nov 14th
  console.log({ now, maintenanceStart, maintenanceEnd })
  // Check if current time is within the maintenance window
  const isInMaintenanceWindow = now >= maintenanceStart && now <= maintenanceEnd;
  console.log("isInMaintenanceWindow", isInMaintenanceWindow);
  
  useEffect(() => {
    if (lovedOne && lovedOne.id) {
      setLoProfile(lovedOne);
      let params = {
        lovedOneId: lovedOne.id,
      };
      getUncheckedPhotoCount(lovedOne.id).then(
        (res) => setUncheckedPhoto(res.data.data)
        // console.log("res", res)
      );
      getUserDetail(params).then((res) => {
        setMenuList(res.menu);
        handleTexts(res.menu);
      });
    }
  }, [lovedOne && lovedOne.id]);

  const text_obj = {
    vmp: `Communicate with ${lovedOne.first_name} by using the icons below. Video Calling, Text Messaging and Photo Sharing have been disabled for ${lovedOne.first_name}.`,
    vm: `Communicate with ${lovedOne.first_name} by using the icons below. Video Calling and Text Messaging have been disabled for ${lovedOne.first_name}.`,
    mp: `Communicate with ${lovedOne.first_name} by using the icons below. Text Messaging and Photo Sharing have been disabled for ${lovedOne.first_name}.`,
    vp: `Communicate with ${lovedOne.first_name} by using the icons below. Video Calling and Photo Sharing have been disabled for ${lovedOne.first_name}.`,
    v: `Communicate with ${lovedOne.first_name} by using the icons below. Video Calling has been disabled for ${lovedOne.first_name}.`,
    m: `Communicate with ${lovedOne.first_name} by using the icons below. Text Messaging has been disabled for ${lovedOne.first_name}.`,
    // p: `Here you can do Video Calling with ${lovedOne.first_name}. Photo Sharing has been disabled for ${lovedOne.first_name}. Try Sharing Photos via Text Message.`,
    p: `Communicate with ${lovedOne.first_name} by using the icons below. Photo Sharing has been disabled for ${lovedOne.first_name}.`,
    all_enable: `Communicate with ${lovedOne.first_name} by using the icons below.`,
  };

  const handleTexts = (menus) => {
    console.log("menus", menus);
    const { menu_video_call, menu_messages, menu_photos } = menus;
    let mt = "";
    if (menu_video_call && menu_messages && menu_photos) {
      mt = text_obj.vmp;
    } else if (menu_video_call && menu_messages) {
      mt = text_obj.vm;
    } else if (menu_messages && menu_photos) {
      mt = text_obj.mp;
    } else if (menu_video_call && menu_photos) {
      mt = text_obj.vp;
    } else if (menu_video_call) {
      mt = text_obj.v;
    } else if (menu_messages) {
      mt = text_obj.m;
    } else if (menu_photos) {
      mt = text_obj.p;
    } else {
      mt = text_obj.all_enable;
    }
    console.log("mt", mt);
    return setMenuText(mt);
  };
  return (
    <>
      <IphonePWAPopup />
      <Layout headerName="menu" noFixedHeader>
        <div className="contentboxpurple">
          <br />
        </div>
        <div className="contentBody_purple contentbodymedia mtminus bottom_logo_pd">
          <div className="profilebox">
            <div className="userprofile ">
              <img
                src={loProfile.photo}
                className="profileimg"
                alt={loProfile.first_name}
                title={loProfile.first_name}
              />
            </div>
          </div>
        
          {isInMaintenanceWindow && (
            <div  style={{
        overflow: 'hidden',
        backgroundColor: '#fff',
      }}>
            <div
              style={{
                color: "red",
                whiteSpace: "nowrap",
                animation: "floatText 15s linear infinite",
                fontWeight: 600,
                fontSize: '15px',
              }}
            >
              <b>Maintenance:</b> Video calling may be unavailable between the hours 9:30 PM to 11:30 PM on the 14th November.
              <style>{`
          @keyframes floatText {
            0% {
              transform: translateX(100%);
            }
            100% {
              transform: translateX(-100%);
            }
          }
        `}</style>
            </div>
            </div>
          )
          }

          <div className="text-center connectwithtexts">
            Connect with
            <h3>{loProfile.first_name}</h3>
            <p className="mt-4">
              {/* Here you can video call {loProfile.first_name} <br />
              or you can send photos. */}
              {menu_text}
            </p>
          </div>
          <div className="contentBody homepageContent">
            <ul className="list-unstyled d-flex flex-column">
              {menu_list?.menu_video_call ? (
                ""
              ) : (
                <li className="text-center mb-3">
                  <div
                  // onClick={() => {
                  //   navigate("/VideoCall", { state: loProfile });
                  // }}
                  >
                    <img
                      style={{ cursor: "pointer" }}
                      src={videoIcon}
                      alt="Video Call"
                      title="Video Call"
                      onClick={() => {
                        navigate("/VideoCall", { state: loProfile });
                      }}
                    />
                  </div>
                </li>
              )}
              {menu_list?.menu_photos ? (
                ""
              ) : (
                <li className="text-center mb-3">
                  <NavLink
                    to="/photos-gallery"
                    className={"position-relative d-inline-block"}
                  >
                    {uncheckedPhoto ? (
                      <label className="imgNoCircleLable">
                        {uncheckedPhoto}
                      </label>
                    ) : (
                      ""
                    )}
                    <img src={photoIcon} alt="Photos" title="Photos" />
                  </NavLink>
                </li>
              )}
              <li className="text-center mb-3">
                <NavLink
                  to={loProfile?.mobile ? `tel:${loProfile.mobile}` : "#"}
                >
                  <img src={phoneIcon} alt="Phone" title="Phone" />
                </NavLink>
              </li>
              {menu_list?.menu_messages ? (
                ""
              ) : (
                <li className="text-center mb-3">
                  <NavLink
                    to={loProfile?.mobile ? `sms:${loProfile.mobile}` : "#"}
                  >
                    <img src={messageIcon} alt="Messages" title="Messages" />
                  </NavLink>
                </li>
              )}
              <li className="text-center mb-3">
                <NavLink to="/contact-us">
                  <img src={helpIcon} alt="Help" title="Help" />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    </>
  );
}
