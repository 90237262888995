import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeLovedOne } from "../redux/lovedOne";
import { useDispatch } from "react-redux";

export default function useSelectedLovedOne() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lovedOne = useSelector((state) => state.lovedOne.value);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    console.log("lovedOne :>> ", lovedOne, Object.keys(lovedOne).length);
    let isLovedOne = Object.keys(lovedOne).length;

    if (!Object.keys(lovedOne).length) {
      let lovedOneList = user?.profile?.lovedOneList;
      if (user.isAuthorized && !isLovedOne) {
        if (sessionStorage.getItem("bpp_lovedOne")) {
          lovedOneList = [JSON.parse(sessionStorage.getItem("bpp_lovedOne"))];
          dispatch(changeLovedOne(lovedOneList[0]));
        }
      }
      console.log("lovedOneList :>> ", lovedOneList);
      if (lovedOneList && lovedOneList.length) {
        if (lovedOneList.length === 0) {
          navigate("/LovedOneList");
        } else {
          dispatch(changeLovedOne(lovedOneList[0]));
          // navigate("/MenuScreen", { state: lovedOneList[0] });
        }
      } else {
        navigate("/LovedOneList");
      }
    }
  }, []);

  return lovedOne;
}
