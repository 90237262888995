import { GET, PUT } from "./request";
import { wrapper } from "utils/helpers";

export const getUncheckedPhotoCount = async (lovedOneId, category_id) => {
  let params = {
    lovedOneId: lovedOneId,
    role: "fm",
  };
  const fn = async () => {
    const response = await GET("/gallery/getUnchekedPhotoCount", {
      params,
    });
    console.log("response :>> ", response);
    return response;
  };

  return wrapper(fn);
};
