import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import bigPurplePhoneLogo from "assets/images/bigPurpleLogo-dark.svg";

import sideMenuButton from "assets/images/menubar_color.svg";
import whiteSideMenuButton from "assets/images/menu_white_icon.svg";
import backNavigateIcon from "assets/images/back_white.svg";
import { getProfile } from "utils/localStorage";
import { useErrorHandler } from "react-error-boundary";
import { logout } from "api/api";

const hamburger_options = [
  // {
  //   name: "Home",
  //   url: "/",
  // },
  {
    name: "My Profile",
    url: "/Profile",
  },
  {
    name: "My Contact",
    url: "/LovedOneList",
    condition: ["My Contact", "My Contacts"],
  },
  {
    name: "FAQs",
    url: "/faqs",
  },
  {
    name: "Help",
    url: "/contact-us",
  },
  // {
  //   name: "Logout",
  //   url: "#",
  // },
];

const Header = ({
  headerName = "loList",
  backNavigate = false,
  headerText = false,
  headerClass = "",
  hidePhoto = false,
  noFixedHeader = false,
  handleNavigate = false,
}) => {
  const [user_profile, setUserProfile] = useState({});
  const handleError = useErrorHandler();
  const navigate = useNavigate();
  useEffect(() => {
    let bpp_profile = getProfile();
    setUserProfile(bpp_profile);
  }, []);
  const handleLogout = async () => {
    await logout()
      .then((res) => {
        localStorage.removeItem("bpp_profile");
        localStorage.removeItem("bpp_jwt_token");
        sessionStorage.removeItem("bpp_lovedOne");
        window.location.href = "/";
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };
  const hamburgerList = () => {
    return hamburger_options.map((h, i) => (
      <li key={i}>
        <a href={h.url}>
          {h.condition
            ? user_profile?.lovedOneList?.length == 0
              ? h.condition[1]
              : h.condition[0]
            : h.name}
        </a>
      </li>
    ));
  };
  return (
    <>
      <div
        className={`text-white ${headerClass} ${
          headerName == "menu" ? "Homeheaderbg_white  height" : "Homeheaderbg"
        }
            ${noFixedHeader ? "" : "headerfixed"}
        }`}
      >
        <div className="d-flex align-items-center justify-content-between">
          {backNavigate ? (
            <img
              src={backNavigateIcon}
              onClick={
                handleNavigate ? handleNavigate : () => navigate(backNavigate)
              }
            />
          ) : (
            <>
              <a
                data-bs-toggle="offcanvas"
                href="#offcanvasExample"
                role="button"
              >
                <img
                  src={
                    headerName == "menu" ? sideMenuButton : whiteSideMenuButton
                  }
                />
              </a>
            </>
          )}

          <h2
            className="fs20 mb-0 text-center mx-auto"
            dangerouslySetInnerHTML={{ __html: headerText }}
          />

          {hidePhoto ? (
            ""
          ) : (
            <div className="prodilepictop">
              <NavLink to="/Profile">
                <img src={user_profile.photo} />
              </NavLink>
            </div>
          )}
        </div>
        {headerName == "menu" ? (
          <div className="pruplelogo_text_tp">
            <img src={bigPurplePhoneLogo} className="mx-auto d-block" />{" "}
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            <a
              href="#"
              role="button"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <img src={sideMenuButton} />
            </a>
          </h5>
        </div>
        <div className="offcanvas-body">
          <ul className="offcanvas_menu">
            {hamburgerList()}
            <li>
              <a href={"#"} onClick={handleLogout}>
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
