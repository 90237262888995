import React, { useEffect, useRef } from "react";

import Layout from "components/Layout";
import "assets/scss/faq.scss";
import user from "redux/user";
export default function Faqs() {
  const faqs = [
    {
      q: "What is the Big<b>Purple</b>Phone?",
      a: "<p>Big<b>Purple</b>Phone is a smartphone designed specifically for Loved One(s) who struggle with technology. When you video call them (or they call you) the process for them is very simple -just one click.</p><p>The Big<b>Purple</b>Phone is larger than a regular smartphone,and has large buttons and large text. It also has - as we like to call it - no fandangle features. Smartphones are often too complex, unreadable and difficult to use for those with age related challenges. Often our Loved One(s) give up and can become isolated by the technology. We created the BigPurplePhone to make communicating with family and friends easier for Loved One(s).</p><p>The Big<b>Purple</b>Phone App means your Loved One(s) has a secure network around them to chat, video call and share with.</p>",
    },
    {
      q: "Why have I received a Big<b>Purple</b>Phone invitation?",
      a: "<p>You have been sent an invite so you can securely video chat,share photos and messages with a Loved One(s) Loved One using the Big<b>Purple</b>Phone app.</p><p>Big<b>Purple</b>Phone is secure from spam and phishing because it is a closed network (if activated). Some Loved One(s) are easily confused by unwanted calls (in fact, so are many people who  aren't Loved One(s)!) so the Big<b>Purple</b>Phone is set up so only people in their secure Family & Friends' network can call them.</p>",
    },

    {
      q: "What is the Big<b>Purple</b>Phone app?",
      a: "<p>The Big<b>Purple</b>Phone app is a free app that can be downloaded on your phone so you can communicate safely and securely with a  loved one on their Big<b>Purple</b>Phone.</p><p>Now you are set up, your Loved One will see you in their contacts and can video call and share photos with you through their Big<b>Purple</b>Phone.</p>",
    },
    {
      q: "What can I do on the Big<b>Purple</b>Phone app?",
      a: "<p>You can communicate with them safely and securely via video chat, you can also share photos, a little like your own social network. You can also message your Loved One(s) and call them as you would normally on your phone, but for them the process is simpler using their Big<b>Purple</b>Phone with big buttons, big text and simpler functionality.</p>",
    },
    {
      q: "Is the Big<b>Purple</b>Phone safe for me to use?",
      a: "<p>Yes. It is an end-to-end video chat, photo sharing and messaging app between you and your loved one.</p>",
    },
    {
      q: "One last thing...",
      a: "<p>When your loved one uses their contact list, it is so much better for them if you upload a profile pic so they can see your face when they call you. Please upload an image of yourself when you confirm your contact details in the app. </p>",
    },
  ];
  // please don't remove these const variables. these are holding refs of questins for scrolling.
  const faqRef0 = useRef();
  const faqRef1 = useRef();
  const faqRef2 = useRef();
  const faqRef3 = useRef();
  const faqRef4 = useRef();
  const faqRef5 = useRef();

  // Top: 0 takes us all the way back to the top of the page
  // Behavior: smooth keeps it smooth!
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScrollToQ = (ref_element) => () => {
    let ref = eval(ref_element);
    let elementPosition = ref.current.getBoundingClientRect().top;
    let offsetPosition = elementPosition - 110;
    window.scroll({
      top: offsetPosition,
      behavior: "smooth",
    });
  };
  return (
    <Layout headerText={"<b>FAQs</b>"} headerName="">
      <div className="pt80">
        <div className="parent">
          <div className="contentboxgray11">
            <br />
          </div>
        </div>
        <div
          className="contentBody contentbodymedia primaryadmin"
          style={{ paddingBottom: "2rem" }}
        >
          <div className="faq_container">
            <h2
              className="mb-4 mt-4 pageleftHeading"
              style={{ fontFamily: "Raleway" }}
            >
              Frequently <b>Asked Questions</b>
            </h2>
            <ul className="list-unstyled faqlist">
              {faqs.map((f, i) => (
                <li className="list-item">
                  <p
                    dangerouslySetInnerHTML={{ __html: f.q }}
                    onClick={handleScrollToQ(`faqRef${i}`)}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="faqcontent_container">
          <div className="faq_container">
            <ul className="list-unstyled faqlist-content">
              {faqs.map((f, i) => (
                <li id={`item-${i}`}>
                  <h4
                    dangerouslySetInnerHTML={{ __html: f.q }}
                    ref={eval(`faqRef${i}`)}
                    key={i}
                    className="mb-5 pb-1"
                  />
                  <p
                    dangerouslySetInnerHTML={{ __html: f.a }}
                    className="mb-5 pb-4"
                  />
                  <p className="backtotops">
                    <p onClick={scrollToTop}>Back to top</p>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
}
