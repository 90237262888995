import React from "react";
import selctContact from "../assets/images/selctContact.svg";
import { useNavigate, useLocation } from "react-router-dom";

export default function RegSucessful() {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickPopup = () => {
    // navigate("/Signin");
    window.location = "/Signin";
  };
  return (
    <div className="mainPage loginPage">
      <div className="successful positioncenter">
        <div className="card  text-center">
          <div className="bluebox">
            <div className="circlemark">
              <img src={selctContact} />
            </div>
            <h5 className="text-white pt-4 mb-0">SUCCESS</h5>
          </div>
          <div className="card-body">
            <p className="card-text">
              Congratulations, you have been successfully Registered.
            </p>

            <button
              type="button"
              onClick={onClickPopup}
              className="btn btn-primary w-100 mt-3"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
