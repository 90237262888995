import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { onMessageListener } from "../utils/firebase";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export default function Notification() {
  //   const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  onMessageListener()
    .then((payload) => {
      //   setShow(true);
      let { data } = payload;
      let url = "MenuScreen";

      if (data.type == "videoCall") {
        url = `VideoCall/${data.caller_role}/${data.caller_id}/${data.room_id}`;
        navigate(`/${url}?d=${JSON.stringify(data)}`, {
          state: payload.data,
        });
      } else if (data.type == "missedVideoCall") {
        toast(data.title, { type: "info" });
        navigate(`/${url}`);
      } else if (data.type == "sharePhoto") {
        toast(data.title, { type: "info" });
      }
    })
    .catch((err) => console.log("failed: ", err));

  return null;
}
