export const setProfile = (data) => {
  localStorage.setItem("bpp_profile", JSON.stringify(data));
};

export const getProfile = () => {
  return JSON.parse(localStorage.getItem("bpp_profile"));
};

export const setToken = (token) => {
  localStorage.setItem("bpp_jwt_token", token);
};

export const getToken = () => {
  return localStorage.getItem("bpp_jwt_token");
};



export const clearLocalStorage = () => {
  localStorage.clear();
};
