import React, { useRef, useState, useEffect } from "react";
import {
  useNavigate,
  NavLink,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import VideoJitsi from "components/VideoCall/VideoJitsi";
import VideoDial from "components/VideoCall/VideoDial";
import VideoRecieve from "components/VideoCall/VideoRecieve";
import { rejectCall } from "api/api";
import { useErrorHandler } from "react-error-boundary";

export default function VideoCall() {
  const navigate = useNavigate();
  const params = useParams();
  const [room_id, setRoomId] = useState("");
  const [is_join, setIsJoin] = useState(false);
  const location = useLocation();
  const handleError = useErrorHandler();
  const roomRef = useRef(room_id);
  let [searchParams, setSearchParams] = useSearchParams();
  roomRef.current = room_id;

  useEffect(() => {
    if (!location.state && !params.room_id) {
      navigate("/MenuScreen");
    }
  }, []);

  const handleJoinParticipant = (payload) => {
    setIsJoin(true);
  };
  const handleAnswerCall = () => {
    setRoomId(params.room_id);
    setIsJoin(true);
  };

  const handleHangupCall = (payload) => {
    setRoomId("");
    setIsJoin(false);
    navigate("/MenuScreen");
  };

  const handleRejectCall = (data) => {
    data.room_id = roomRef.current;
    rejectCall(data)
      .then((res) => {
        handleHangupCall();
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };

  return (
    <div className="darkgraybg p-0">
      <div className="mainPage">
        {params.room_id ? (
          <VideoRecieve
            queryParam={{
              ...JSON.parse(decodeURIComponent(searchParams.get("d"))),
              ...params,
            }}
            is_join={is_join}
            handleHangupCall={handleHangupCall}
            handleAnswerCall={handleAnswerCall}
            handleRejectCall={handleRejectCall}
            room_id={room_id}
          />
        ) : (
          <VideoDial
            handleHangupCall={handleHangupCall}
            setRoomId={setRoomId}
            is_join={is_join}
            handleRejectCall={handleRejectCall}
            room_id={room_id}
          />
        )}

        {room_id && (
          <VideoJitsi
            room_id={room_id}
            handleJoinParticipant={handleJoinParticipant}
            is_join={is_join}
            handleHangupCall={handleHangupCall}
            users={location.state}
            params={params}
          />
        )}
      </div>
    </div>
  );
}
