import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate, useLocation, NavLink } from "react-router-dom";

import blackTop from "assets/images/blackTop.svg";
import loginLogo from "assets/images/loginLogo.svg";
import bigPurplePhoneLogo from "assets/images/bigPurplePhoneLogo.svg";

import {
  verifyOtpMobile,
  sendOtpMobile,
  verifyOtpUpdateMobile,
  sendOtpUpdateMobile,
} from "api/api";

export default function OtpVerify() {
  const location = useLocation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }
  }, []);
  const onChangeInput = (otp) => setOtp(otp);
  const handleBackNaviagte = () => {
    if (location.state?.type == "updateMobile") {
      navigate("/Profile");
    } else {
      navigate("/Signin");
    }
  };
  const onClickResendOtp = () => {
    let callResendOtpApi =
      location.state?.type == "updateMobile"
        ? sendOtpUpdateMobile
        : sendOtpMobile;
    callResendOtpApi({ mobile: location.state.mobile })
      .then((result) => {
        console.log(result);
        // navigate("/OtpVerify", { state: { mobile: location.state.mobile } });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onClickOtpVerify = async () => {
    const OTP = Number(otp);
    let err = "";

    if (!otp) {
      err = "Please Enter OTP.";
    } else if (otp < 1000) {
      err = "Invalid Otp";
    }

    if (err.length > 0) {
      setError(err);
    } else {
      if (location.state.type == "updateMobile") {
        let data = {
          otp: OTP,
          mobile: location.state.mobile,
        };
        await verifyOtpUpdateMobile(data).then((res) => {
          console.log("res", res);
          navigate("/Profile");
        });
      } else {
        verifyOtpMobile(OTP, location.state.mobile)
          .then((result) => {
            console.log(result);
            //   earlier was changepassword route
            navigate("/ResetPassword", {
              state: { mobile: location.state.mobile },
            });
          })
          .catch((error) => {
            console.log(error);
            setError("Invalid Otp");
          });
      }
    }
  };

  return (
    <div className="mainPage loginPage">
      <div className="headerTop">
        <img
          src={blackTop}
          alt=""
          className="backIconTop"
          onClick={handleBackNaviagte}
        />
      </div>
      <div className="loginLogo">
        <img src={loginLogo} alt="" />
      </div>
      <div className="contentBody">
        <h1>
          <span>Verify Mobile Number</span>
        </h1>
        <p className="mb-4">
          Please enter OTP you receive on your Mobile Number
        </p>
        <div className=" mb-3">
          <div className="d-flex">
            <OtpInput
              className="otpbox otpInput"
              value={otp}
              onChange={onChangeInput}
              numInputs={4}
              separator={<span> </span>}
            />
          </div>
          <span id="span" style={{ color: "red" }}>
            {error}
          </span>
          <p className="pt-3">
            Didn't Receive OTP ?{" "}
            <span
              className="clrblue fsregular crsrPointer"
              onClick={onClickResendOtp}
            >
              Resend OTP
            </span>
          </p>
        </div>
        <div className="button-margin">
          <div className="col-lg-12">
            <button
              onClick={onClickOtpVerify}
              type="button"
              className="btn btn-primary w-100 mb-4"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <div className="bigpurplebottom text-center pb-4">
        <img src={bigPurplePhoneLogo} alt="" />
      </div>
    </div>
  );
}
// }
