import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import back_white from "../assets/images/back_white.svg";
import infoFeather from "../assets/images/infoFeather.svg";

import CustomPasswordInput from "../components/CustomPasswordInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import messages from "utils/messages";
import { changeFAPassword } from "../api/api";
import { passwordRegex } from "utils/regex";
import { useErrorHandler } from "react-error-boundary";

export default function ChangePassword() {
  const navigate = useNavigate();

  const handleError = useErrorHandler();
  const ChangePasswordSchema = Yup.object().shape({
    password: Yup.string().required(messages.required.oldPassword),
    newPassword: Yup.string()
      .required(messages.required.newPassword)
      .matches(passwordRegex, messages.invalidPassword)
      .notOneOf([Yup.ref("password"), null], "Your new password needs to be different from your old password."),
    confirmPassword: Yup.string()
      .required(messages.required.confirmPassword)
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
      role: "pfa",
    },
    validationSchema: ChangePasswordSchema,

    onSubmit: (values) => {
      console.log("values :>> ", values);
      const castValues = ChangePasswordSchema.cast(values);
      changeFAPassword(castValues.password, castValues.newPassword)
        .then((res) => {
          console.log("res :>> ", res);
          if (res) {
            navigate("/ChangePasswordSucessful");
          }
        })
        .catch((error) => {
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        });
    },
    enableReinitialize: true,
  });
  return (
    <div className="pt80">
      <div className="mainPage">
        <div className="Homeheaderbg headerfixed text-white ">
          <div className="d-flex align-self-center">
            <NavLink to="/Profile">
              <img src={back_white} alt="" title="" />
            </NavLink>
            <h2 className="fs20 mb-0 text-center mx-auto">Change Password</h2>
          </div>
        </div>
        <div className="parent">
          <div className="contentboxgray11">
            <br />
          </div>
        </div>

        <div className="contentBody contentbodymedia ">
          <div className="contentBody">
            <h3 className="fsregular">Change Password</h3>
            <small className="fs12">
              <span className=" pe-2">
                <img src={infoFeather} alt="" title="" />
              </span>

              {messages.invalidPassword}
            </small>
            <div className="">
              <small className="fs12">
                <span className="pe-2">
                  <b>*Required fields</b>
                </span>
              </small>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className="row pt-3">
                <div className="col-lg-12">
                  <div className="mb-3">
                    <CustomPasswordInput
                      formik={formik}
                      name="password"
                      label="Password"
                      required
                    />
                    {/* <span style={{ color: "red" }}>
                      {formError.serverError}
                    </span> */}
                  </div>
                  <div className="mb-3">
                    <CustomPasswordInput
                      formik={formik}
                      name="newPassword"
                      label="New Password"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <CustomPasswordInput
                      formik={formik}
                      name="confirmPassword"
                      label="Confirm Password"
                      required
                    />
                  </div>
                  {/* <small className="fs12">
                    <span className=" pe-2">
                      <img src={infoFeather} alt="" title="" />
                    </span>

                    {messages.invalidPassword}
                  </small>
                  <div className="">
                    <small className="fs12">
                      <span className="pe-2">
                        <b>*=Required fields</b>
                      </span>
                    </small>
                  </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 mt-3"
                  // onClick={onClickSumbit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
