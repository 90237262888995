import { useEffect, useState } from "react";
import { getFAContactList, sharePhotos } from "../../api/api";
import userSmall from "../../assets/images/user-small.svg";
import noContactImage from "../../assets/images/no-contact1.svg";
import { useErrorHandler } from "react-error-boundary";
export default function SharePhotos({ handleCloseModal, photos }) {
  const [list, setList] = useState([]);
  const [isError, setError] = useState(false);
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const handleError = useErrorHandler();
  useEffect(() => {
    setLoading(true);

    getFAContactList()
      .then((result) => {
        setList(result);
      })
      .catch((error) => {
        setList([]);
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const handleSelectContact = (lo) => (event) => {
    setError(false);

    let selected_users = [...users];
    if (event.target.checked) {
      selected_users.push(lo.id);
    } else {
      const index = selected_users.indexOf(lo.id);
      if (index > -1) {
        selected_users.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    setUsers(selected_users);
  };
  let contacts_list = list.length ? (
    list.map((item, i) => (
      <div className="media boxmediawhite mt-1 m-0 slectBlogNormal p-0" key={i}>
        <div className="checkBoxSelect d-flex w-100">
          <input
            type="checkbox"
            id={item.id}
            style={{ visibility: "collapse" }}
            onChange={handleSelectContact(item)}
          />
          <label for={item.id} className="d-flex ">
            <div className="imghoverbox">
              <img
                className="align-self-center imageover"
                src={item.photo ? item.photo : userSmall}
                alt={item.first_name}
                title={item.first_name}
              />
            </div>
            <div className="media-body">
              <h5 className="mt-0 mb-0">{item.first_name}</h5>
            </div>
          </label>
        </div>
      </div>
    ))
  ) : (
    <div className="text-center nocontact">
      <img src={noContactImage} style={{ width: "25%" }} alt="no contact" />
      <h3 className="pt-4">No Contacts Found</h3>
    </div>
  );

  const handleSharePhotos = () => {
    let data = {
      photos: photos,

      shareTo: users.map((userId, i) => {
        return { id: userId, role: "loved_one" };
      }),
      role: "users",
    };

    console.log("data :>> ", data);

    if (users.length) {
      sharePhotos(data)
        .then((res) => {
          console.log("res :>> ", res);
          handleCloseModal();
        })
        .catch((error) => {
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        });
    } else {
      setError(true);
    }
  };
  return (
    // <div className="pt80">
    //   <div className="mainPage"> contentBody contentboxgray text-center positioncenter
    <div className="">
      <div className="contentbodypadd p-0">
        <div className="col-md-12">
          <div className="popupbox">
            <div className="popcontent">
              <h2 className="fs24" style={{ textAlign: "left" }}>
                Select
              </h2>

              {contacts_list}
              <span className="error">
                {isError
                  ? "Please select atleast one user to share photos"
                  : ""}
              </span>

              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-danger me-2 w-100 mt-3"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary w-100 mt-3"
                  onClick={handleSharePhotos}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    //   </div>
    // </div>
  );
}
