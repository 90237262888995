import { Provider, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import MenuScreen from "screens/MenuScreen";
import ManageSetting from "screens/ManageSetting";
import Profile from "screens/Profile";
import ErrorFallback from "components/Error";
import { ErrorBoundary } from "react-error-boundary";
import EditProfile from "screens/EditProfile";
import PopPhoto from "screens/PopPhoto";
import RegSucessful from "screens/RegSucessful";
import ChangePasswordSucessful from "screens/ChangePasswordSucessful";
import PhotoUploadSucc from "screens/PhotoUploadSucc";
// import ForgotPass from "screens/ForgotPass";
import OtpVerify from "screens/OtpVerify";
import ChangePassword from "screens/ChangePassword";
// import ResetPassword from "screens/ResetPassword";
import Contacts from "screens/Contacts";
import PhotosGallery from "screens/photosGallary/PhotosGallery";
import FMSingleImage from "screens/photosGallary/FMSingleImage";
import FMCommentImage from "screens/photosGallary/FMCommentImage";
import FMPhotoSelection from "screens/photosGallary/FMPhotoSelection";
import VideoCall from "screens/VideoCall";
import FAHelpSupport from "screens/FAHelpSupport";
import LovedOneList from "screens/LovedOneList";
import Faqs from "screens/Faqs";
let authRoutes = [
  { component: MenuScreen, path: "MenuScreen" },
  { component: ManageSetting, path: "ManageSetting" },
  { component: Profile, path: "Profile" },
  { component: EditProfile, path: "EditProfile" },
  { component: PopPhoto, path: "PopPhoto" },
  { component: RegSucessful, path: "RegSucessful" },
  { component: ChangePasswordSucessful, path: "ChangePasswordSucessful" },
  { component: PhotoUploadSucc, path: "PhotoUploadSucc" },
  { component: ChangePassword, path: "ChangePassword" },
  { component: Contacts, path: "Contacts" },
  { component: Contacts, path: "VideoContacts", props: { page: "video" } },
  { component: PhotosGallery, path: "photos-gallery" },
  { component: FMSingleImage, path: "FMGallaryImage/:id" },
  { component: FMCommentImage, path: "FMCommentImage" },
  { component: FMPhotoSelection, path: "FMPhotoSelection" },
  { component: VideoCall, path: "VideoCall" },
  // { component: VideoCall, path: "VideoCall/:role/:caller_id/:room_id" },
  { component: FAHelpSupport, path: "FAHelpSupport" },
  { component: LovedOneList, path: "LovedOneList" },
  { component: Faqs, path: "faqs" },
];

const Authorized = ({ children }) => {
  const user = useSelector((state) => state.user);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {user.isAuthorized ? children : <Navigate to="/" />}
    </ErrorBoundary>
  );
};

export default function AuthRoutes() {
  return authRoutes.map((r, i) => {
    let AuthComponent = r.component;
    return (
      <Route
        path={`/${r.path}`}
        element={
          <Authorized>
            <AuthComponent page={r.props?.page} />
          </Authorized>
        }
        key={i}
      />
    );
  });
}
