import React, { useState, useReducer, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

import iconDelete from "assets/images/icon-delete.svg";
import left_arrow from "assets/images/left_arrow.svg";
import right_arrow from "assets/images/right_arrow.svg";
import IconfeatherHeart from "assets/images/Icon feather-heart.svg";
import heartFill from "assets/images/heartFill.svg";
import {
  getGallaryPhotoById,
  deleteFMphotos,
  likeDislikeFMphotos,
} from "api/api";
import deleteConfirmIcon from "assets/images/delete_confrm.svg";

import SharePhotos from "./SharePhotos";
import { useErrorHandler } from "react-error-boundary";
import Layout from "components/Layout";
import useSelectedLovedOne from "hooks/useSelectedLovedOne";

export default function FMSingleImage({ handleUpdate }) {
  const param = useParams();
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const [item, setItem] = useState([]);
  const [is_like, setIsLike] = useState(false);

  const [isOpenModal, setOpenModal] = useState(false);
  const lovedOne = useSelectedLovedOne();

  useEffect(() => {
    setIsLike(item.is_like);
  }, [item && item.id]);

  useEffect(() => {
    if (lovedOne && lovedOne.id) {
      let params = {
        role: "users",
        id: param.id,
        lovedOneId: lovedOne.id,
      };

      getGallaryPhotoById(params)
        .then((res) => {
          const { data } = res.data;
          setItem(data);
          setIsLike(data.is_like);
        })
        .catch((error) => {
          return error.response.data.statusCode != 400
            ? handleError(error)
            : navigate("/NotFound");
        });
    }
  }, [param.id, lovedOne && lovedOne.id]);
  const onClickDeletePhoto = async () => {
    let data = {
      id: item.id,
      role: "users",
    };
    console.log("yess it's coming");
    deleteFMphotos(data)
      .then((res) => {
        handleModal(false);
        navigate("/photos-gallery");
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };

  const handleModal = (status) => () => {
    setOpenModal(status);
  };
  const toggleLike = () => {
    likeDislikeFMphotos({
      id: item.id,
      isLike: is_like === 0 ? 1 : 0,
      role: "users",
    })
      .then((res) => {
        setIsLike(is_like === 0 ? 1 : 0);
        handleUpdate({ id: item.id, is_like: is_like === 0 ? 1 : 0 });
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };

  const handleArrow = (id) => () => {
    navigate(`/FMGallaryImage/${id}`);
  };

  return (
    <div className="pt80">
      <Layout
        headerName=""
        headerText="View <b>Photo</b>"
        backNavigate={"/photos-gallery"}
      >
        <div className="parent">
          <div className="contentboxgray11">
            <br />
          </div>
        </div>
        <div className="contentBody contentbodymedia pt-3 bgGrayFull">
          <div className="contentBody">
            <div className="row">
              <div className="col-12">
                <div className="fix-img-container">
                  <div className="fullimg">
                    <img
                      src={item.photo}
                      className="bigimgview img-full-height"
                    />
                    <img
                      src={iconDelete}
                      onClick={handleModal("delete")}
                      className="smallimgview"
                    />{" "}
                    {item.prev_id > 0 ? (
                      <img
                        src={left_arrow}
                        className="leftSlideArrow"
                        onClick={handleArrow(item.prev_id)}
                      />
                    ) : (
                      " "
                    )}
                    {item.next_id ? (
                      <img
                        src={right_arrow}
                        className="rightSlideArrow"
                        onClick={handleArrow(item.next_id)}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="text-center">
                  <p>{item.description}</p>
                </div>
                <div className="shareIconsBlog">
                  <div className="wishlistbox ">
                    <img
                      src={is_like === 0 ? IconfeatherHeart : heartFill}
                      onClick={toggleLike}
                    />
                  </div>
                  {/* <div
                    className="wishlistbox purpleBg "
                    onClick={handleModal("share")}
                  >
                    <a href="#">
                      <img src={share} />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          centered
          show={isOpenModal == "delete"}
          onHide={handleModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3 className="text-center">Confirmation</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="mb-3 text-center">
              <img src={deleteConfirmIcon} alt="delete confirmation" />
            </p>

            <p className="text-center">
              Are you sure you want to delete this photo ?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleModal(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={onClickDeletePhoto}
            >
              Delete
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          centered
          show={isOpenModal == "share"}
          onHide={handleModal(false)}
        >
          <SharePhotos
            photos={[item.photoId]}
            handleCloseModal={handleModal(false)}
          />
        </Modal>
      </Layout>
    </div>
  );
}
