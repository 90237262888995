// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { setDeviceToken } from "../api/api";
import { toast } from "react-toastify";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyArl6lgumH9rVIszVnOq02MWbZ830-s0Co",
  authDomain: "bpp-family-and-friends.firebaseapp.com",
  projectId: "bpp-family-and-friends",
  storageBucket: "bpp-family-and-friends.appspot.com",
  messagingSenderId: "861967886842",
  appId: "1:861967886842:web:ff1c1b3f1cac4830afdc43",
  measurementId: "G-WV8N1LZD3J",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
firebaseApp.automaticDataCollectionEnabled = true;
// const analytics = getAnalytics(app);
export const getDeviceToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BM_2qrJ6al8aM2HlDv9JlV09V-NZlQQq3-TxUxEwcknOMLcZsc6_EdRbbhhZ2kc_Ipxt9onLPuB3obCwH4gZtPA",
  })
    .then(async (currentToken) => {
      if (currentToken) {
        if (
          typeof localStorage == "object" &&
          localStorage.getItem("bpp_profile")
        ) {
          let id = JSON.parse(localStorage.getItem("bpp_profile")).id;
          await setDeviceToken({
            device_token: currentToken,
            role: "fm",
            id,
          });
        }
        return currentToken;
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
      // toast("Please enable notification for recieving notifications", {
      //   type: "error",
      // });
    });
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload onmessagelistener:>> ", payload);
      resolve(payload);
    });
  });
