import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import back_white from "assets/images/back_white.svg";
import fullimgupload from "assets/images/fullimgupload.svg";

import left_arrow from "assets/images/left_arrow.svg";
import right_arrow from "assets/images/right_arrow.svg";

import { addPhotos } from "api/api";
import { Spinner } from "react-bootstrap";
import { useErrorHandler } from "react-error-boundary";
import Layout from "components/Layout";

export default function FMCommentImage() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleError = useErrorHandler();
  const [form, setForm] = useState({
    imgs: Array.from(location.state.photos),
    comment: "",
    preview: location.state.previewUrls,
    lovedOneId: location.state.lovedOneId,
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [errors, setError] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const handleAddImages = async () => {
    // check if any field has error
    let isValidate = Object.values(errors).every((item) => item == false);

    if (isValidate) {
      setSubmitting(true);
      let data = new FormData();
      data.append("comment", form.comment);
      data.append("role", "users");
      data.append("lovedOneId", form.lovedOneId);
      form.imgs.map((f, i) => data.append("photos", f));
      addPhotos(data)
        .then((res) => {
          setTimeout(() => {
            setSubmitting(false);
            navigate("/photos-gallery");
          }, 3500);
        })
        .catch((error) => {
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        });
    }
  };
  const handlePrev = (e) => {
    e.preventDefault();

    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const handleNext = (e) => {
    e.preventDefault();

    if (form.imgs.length - 1 > currentIndex) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleChangeComment = (e) => {
    let comment = e.target.value;
    setForm((prev) => ({
      ...prev,
      comment,
    }));
    if (comment.length > 25) {
      setError({
        comment: true,
      });
    } else {
      setError({
        comment: false,
      });
    }
  };

  return (
    <div className="pt80">
      <Layout
        headerText={"Add <b>Photos</b>"}
        headerName="photos"
        backNavigate="/photos-gallery"
        hidePhoto={true}
        noFixedHeader
      >
        <div className="parent">
          <div className="contentboxgray11">
            <br />
          </div>
        </div>
        <div className="contentBody contentbodymedia contentboxgray pt-4">
          <div className="contentBody">
            <div className="row">
              <div className="col-12">
                <div className="fullimg">
                  <img
                    src={
                      form.preview[currentIndex]
                        ? form.preview[currentIndex]
                        : fullimgupload
                    }
                    className="bigimgview"
                  />
                  {form.imgs && form.imgs.length > 1 ? (
                    <>
                      {" "}
                      {currentIndex > 0 ? (
                        <a href="#">
                          {" "}
                          <img
                            src={left_arrow}
                            className="leftSlideArrow"
                            onClick={handlePrev}
                          />
                        </a>
                      ) : (
                        " "
                      )}
                      {currentIndex + 1 == form.imgs.length ? (
                        ""
                      ) : (
                        <a href="#">
                          <img
                            src={right_arrow}
                            className="rightSlideArrow"
                            onClick={handleNext}
                          />
                        </a>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mb-3">
                  <div className="inputIcon">
                    <h5>Add Comment</h5>
                    <textarea
                      id="msz"
                      name="msz"
                      rows="4"
                      width="100%"
                      placeholder="Enter Comment..."
                      value={form.comment}
                      onChange={handleChangeComment}
                    />
                    {errors.comment ? (
                      <span className="error">
                        Character limit is upto 25 Characters
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="relativeLoader">
                  {isSubmitting ? (
                    <div className="loaderover">
                      <div className="flex_loaderbox">
                        <Spinner animation="border" variant="primary" />
                        <span>Uploading...</span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* <div className="d-flex">
                    <button
                      type="button"
                      className="btn btn-primary w-100 me-2 btnwhite textred"
                      onClick={() => navigate("/GallaryImages")}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary w-100 ms-2"
                      onClick={handleAddImages}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <div className="loaderover">
                          <div className="flex_loaderbox">
                            <Spinner animation="border" variant="primary" />
                            <span>
                              Sharing your photos with {loved_one.first_name}
                            </span>
                          </div>
                        </div>
                      ) : (
                        "Add"
                      )}
                    </button>
                  </div> */}
                  <div className="d-flex">
                    <button
                      type="button"
                      className="btn btn-primary w-100 me-2 btnwhite textred"
                      onClick={() => navigate("/photos-gallery")}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary w-100 ms-2"
                      onClick={handleAddImages}
                      disabled={isSubmitting}
                    >
                      {/* {isSubmitting ? (
                        <Spinner animation="border" variant="primary" />
                      ) : (
                        "Add"
                      )} */}
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
