const messages = {
  enterName: "Please Enter Name",
  enterPhone: "Please Enter Phone Number",
  enterEmail: "Please Enter Email",
  invalidName: "Invalid Name",
  invalidEmail: "Please Enter Valid Email",
  invalidPhone: "Invalid Phone Number",
  // invalidPassword:
  //   "Password must contain at least 6 characters including uppercase, lowercase, numbers and special characters",
  invalidPassword:
    "Password must contain at least 8 characters including at least one special character and one number",

  matchPassword: "Password does not match",
  enterPassword: "Please Enter Password",
  enterNewPassword: "Please Enter New Password",
  enterConfirmPassword: "Please Enter Confirm Password",
  enterStreet: "Please Enter Street",
  enterPostcode: "Please Enter Postcode",
  matchString: "Please Enter Valid Input",
  required: {
    name: "Please Enter Name",
    firstName: "Please Enter First Name",
    lastName: "Please Enter Last Name",
    dob: "Please Enter Date of Birth",
    carrier: "Please Select Carrier",
    accountNo: "Please Enter Account No",
    phone: "Please Enter Phone Number",
    password: "Please Enter Password",
    oldPassword: "Please Enter Your Current Password",
    newPassword: "Please Enter New Password",
    confirmPassword: "Please Enter Confirm Password",
    email: "Please Enter Email",
    relation: "Please Select Relation",
    relationInput: "Please Specify Your Relation",
    role: "Please Select Role",
    street: "Please Enter Street",
    suburb: "Please Enter Suburb",
    state: "Please Enter State",
    postal_code: "Please Enter Postal Code",
    file: "Please Upload File",
    check: "Please Check The Field",
  },
  atleastOne: {
    role: "Please Select At Least One Role",
    option: "Please Select At Least One Option ",
  },
  maxLength: "Character Limit is upto 50",
  email: "Please Enter Valid Email",
};

export default messages;
