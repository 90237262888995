import Footer from "components/Layout/Footer";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";

import splashLogo from "../assets/images/splashLogo.svg";

export default function SplashScreen() {
  const [isWaiting, setIsWaiting] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsWaiting(false);
    }, 2000);
  });

  if (!isWaiting) {
    return <Navigate to="/MenuScreen" />;
  }

  return (
    <div className="mainPage purBg">
      <div className="logo spleshBlog">
        <a href="#">
          <img src={splashLogo} style={{ width: "230px" }} />
        </a>
      </div>
      <Footer />
    </div>
  );
}
